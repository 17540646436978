import React, { useState, useReducer, useEffect } from "react";
import { useFormik } from "formik";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    HStack,
    Spacer,
    Textarea,
    Switch,
    Stack,
    RadioGroup,
    Flex,
    useDisclosure
} from "@chakra-ui/react";
import {
    EditIcon,
    DeleteIcon
} from '@chakra-ui/icons'
import * as Yup from 'yup'
import '@splidejs/react-splide/css';
import Resizer from "react-image-file-resizer"
import { useLocation } from 'react-router-dom';

import "../../App.css";
import {
    setValueToWord,
    getElementIdByIndex,
    getValueFromWord
} from "../../WordDataFunction"
import EditDialog from "./EditDialog"
import NotificationBar from "./NotificationBar"
import useApi from "../../hooks/useApi"
import BackButton from "../BackButton";
import WordEpisode from "../WordEpisode";
import FullCoverSpinner from "../FullCoverSpinner";
import PosList from "./PosList";
import WordTags from "./WordTags";
import ErrorDialog from "../ErrorDialog";
import { useUserContext } from "../../context/userContext";

import SearchWord from '../search/SearchWord'
import SearchResultCandidate from "../search/SearchResultCandidate"

const DisplayWord = (props) => {

    const {
        commitActivity,
        upsertWord,
        uploadSentenceImages,
        deleteSentenceImages,
        isLoading
    } = useApi()

    const {
        getTotalWordCount,
        incrementTotalWordCount,
        getWordCountQuota,
        getTotalSentenceImageCount,
        getSentenceImageCountQuota
    } = useUserContext()

    /////////////////////////////////////////////////////////////////////
    // get a url parameter "isdebug"
    const query = new URLSearchParams(useLocation().search);
    const isdebug = query.get('isdebug');    

    /////////////////////////////////////////////////////////////////////
    // Images in user defined sentences must be read through userSentenceImages[sentenceId]
    // imageRepo has all records of 'add', 'update', or 'delete' for images
    //
    const [userSentenceImages, setUserSentenceImages] = useState({})

    const resizeFile = (file) => 
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                512,
                512,
                "JPEG",
                100,
                0,
                (uri) => {resolve(uri)},
                "base64",
                512,
                512
            )
        })

    const imageFunc = async (imageRepo, action) => {
        if (action.type === "clear") {
            return {}
        } else if (action.type === "abort") {
            const id = action.id
            if (action.image === null && id in userSentenceImages) {
                delete userSentenceImages[id]
            } else {
                userSentenceImages[id] = action.image
            }
            setUserSentenceImages({...userSentenceImages})

            delete imageRepo[id]
            return {...imageRepo}

        } else {
            const id = action.id
            const type = action.type
            const repo = await imageRepo
            const updatedImageRepo = {
                ...repo,
                [id]: ["addFile", "addImage"].includes(type) ? "add" : type
            }

            if (action.type === "addFile") {
                const image = await resizeFile(action.file)
                setUserSentenceImages({
                    ...userSentenceImages,
                    [id]: image
                })
            } else if (action.type === "addImage") {
                setUserSentenceImages({
                    ...userSentenceImages,
                    [id]: action.image
                })
            } else if (action.type === "delete") {
                if (id in userSentenceImages) {
                    delete userSentenceImages[id]
                    setUserSentenceImages({...userSentenceImages})
                }
            }

            return updatedImageRepo
        }
    }

    const [imageRepo, imageDispatch] = useReducer(imageFunc, {})

    useEffect(() => {
        setUserSentenceImages({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.displayWordParams.wordId])

    /////////////////////////////////////////////////////////////////////
    // spinner

    const [requestId, setRequestId] = useState(null)

    const { 
        isOpen: isSpinnerOpen,
        onOpen: onSpinnerOpen,
        onClose: onSpinnerClose
    } = useDisclosure()    

    useEffect(()=> {
        if (requestId) {
            if (!isLoading(requestId) && !isSpinnerOpen) {
                onSpinnerOpen()
            }
        } else if (isSpinnerOpen) {
            onSpinnerClose()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestId])


    //////////////////////////////////////////////////////////
    // update selectedMeaningId when it's updated
    const [selectedMeaningId, setSelectedMeaningId] = useState(null)

    useEffect(()=> {
        setSelectedMeaningId(props.displayWordParams.selectedMeaningId)
    }, [props.displayWordParams.selectedMeaningId])

    //////////////////////////////////////////////////////////

    const onSubmit = async () => {
        let wordData = props.displayWordParams.wordData
        
        if (selectedMeaningId) {
            wordData = setValueToWord(wordData, "selectedMeaningId", selectedMeaningId)
        }

        if (formik.dirty) {
            wordData = setValueToWord(wordData, "notes", formik.values.notes)
        }

        const isNewWord = getValueFromWord(wordData, "isNew")

        try {
            const requestId = crypto.randomUUID()
            setRequestId(requestId)
            wordData = await upsertWord(wordData, requestId)

            if (wordData?.status === "fail") {
                if (wordData?.results?.exceedWordCountQuota) {
                    setErrorMessage({
                        title: "単語を登録できません",
                        texts: [
                            `登録可能な単語上限数 (${getWordCountQuota()}) に達しているため、登録できませんでした。`,
                            "既に登録済みの単語を削除してから再度登録してください。"
                        ]
                    })
                } else {
                    setErrorMessage({
                        title: "単語の登録に失敗しました",
                        texts: [
                            "単語の登録に失敗しました。"
                        ]
                    })
                }
                setDispatchType("error")
                onErrorDialogOpen()

            } else if (wordData) { 
                // increment userInfo.settings.totalWordCount if it's a new word
                if (isNewWord) {
                    incrementTotalWordCount()
                }

                // upload/delete images on the backend
                const deletedIds = []
                const uploadStreamInfos = []

                const repo = await imageRepo
                Object.keys(repo).forEach((id) => {
                    const type = repo[id]
                    if (type === "add") {
                        uploadStreamInfos.push([id, userSentenceImages[id]])
                    } else {
                        deletedIds.push(id)
                    }
                })

                if (deletedIds.length > 0) {
                    await deleteSentenceImages(deletedIds)
                }

                if (uploadStreamInfos.length > 0) {
                    const response = await uploadSentenceImages(JSON.stringify(uploadStreamInfos))

                    const totalSentenceImageCount = await getTotalSentenceImageCount()
                    const sentenceImageCountQuota = await getSentenceImageCountQuota()
                    if ((response && response?.status && response.results?.exceedSentenceImageCountQuota)
                                || totalSentenceImageCount >= sentenceImageCountQuota) {
                        setErrorMessage({
                            title: "例文画像をこれ以上登録できません",
                            texts: [
                                `登録可能な例文画像上限数 (${sentenceImageCountQuota}) を超過したため、これ以上例文画像を登録できません。`,
                                "既に登録済みの例文画像を削除してから上限の範囲内で例文画像登録を行ってください。"
                            ]
                        })
                        setDispatchType("dwell")
                        onErrorDialogOpen()
                    }
                }

                imageDispatch({"type": "clear"})
            }
        } catch (error) {
            console.log(`error: ${error}`)
        } finally {
            setRequestId(null)
        }

        props.dispatch({
            type: "displayWordSaved",
            data: {
                previous:{
                    search: props.searchParams
                },
                current: {
                    wordData,
                    editDialogParams: {
                        isOpen: false
                    }
                }
            }
        })
    }

   //////////////////////////////////////////////////////////
    // formik and yup settings
    const validationSchema = Yup.object({
        notes: Yup.string().max(512, "512文字以内で入力してください")
    })

    const formikInitialValues = {
        notes: props.displayWordParams.notes ? props.displayWordParams.notes : ""
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: formikInitialValues,
        onSubmit,
        validationSchema
    });
    //////////////////////////////////////////////////////////

    useEffect(() => {
        if (props.displayWordParams.mode === "browseWord") {
            commitActivity(
                props.displayWordParams.wordId,
                props.displayWordParams.cId,
                "browse",
                props.displayWordParams.displayName,
                props.displayWordParams.selectedMeaning
            )            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.displayWordParams.wordId])

    //////////////////////////////////////////////////////////

    const [errorMessage, setErrorMessage] = useState({title:"", texts:[]})
    const [dispatchType, setDispatchType] = useState("error")

    //////////////////////////////////////////////////////////

    const { 
        isOpen: isErrorDialogOpen,
        onOpen: onErrorDialogOpen,
        onClose: onErrorDialogClose
    } = useDisclosure()

    //////////////////////////////////////////////////////////
    // scroll back to the previous position

    useEffect(()=> {
        console.log(`props.scrollPosition: ${props.scrollPosition}`)
        if (typeof props.scrollPosition === "number") {
            window.scrollBy({
                left: 0,
                top: props.scrollPosition,
                behavior: "smooth"
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    //////////////////////////////////////////////////////////

    return (
        <Stack
            // textAlign="left"
            // margin="0% 2% 0% 2%"
            // width="96%"
            // maxW="700px"
            // justifyContent="center"
            // alignItems="center"
            margin="0%"
            textAlign="left"
            p="1"
            rounded="md"
            width="100%"            
        >
            <FullCoverSpinner
                isOpen={isSpinnerOpen} 
                onClose={onSpinnerOpen}
            />

            <ErrorDialog
                isOpen={isErrorDialogOpen}
                onClose={onErrorDialogClose}
                errorMessage={errorMessage}
                dispatchType={dispatchType}
                dispatch={props.dispatch}
            />

            <SearchWord
                query={props.searchParams.query}
                setRequestId={setRequestId}
                displayWordParams={props.displayWordParams}
                dispatch={props.dispatch}
                setLaunchSubApp={props.setLaunchSubApp}
            />

            {
                props.searchParams.candidates ?
                    <Stack
                        textAlign="left"
                        rounded="md"
                        justifyContent="center"
                        alignItems="center"
                        width="100%"                
                    >
                        {
                            props.searchParams.hasPerfectMatch ?
                                <></>
                                :
                                <SearchResultCandidate
                                    query={props.searchParams.query}
                                    candidates={props.searchParams.candidates}
                                    setRequestId={setRequestId}
                                    dispatch={props.dispatch}
                                />
                        }
                    </Stack>
                    :
                    <></>
            }
            {
                props.displayWordParams.mode === "empty" ?
                    <></>
                    :
                    props.displayWordParams.mode === "registerNewUserWord" ?
                        <Stack
                            justifyContent="center"
                            alignItems="center"
                            m="30px"
                        >
                            <Box>
                                "{props.displayWordParams.displayName}" は辞書未登録単語です。新規登録には編集が必要です。編集しますか？
                            </Box>
                            <Box>
                                <Button
                                    colorScheme="blue"
                                    onClick={()=>{
                                        props.dispatch({
                                            type: "displayWordEditDialogOpened",
                                            data: {
                                                previous:{
                                                    search: props.searchParams
                                                },
                                                current: {
                                                    wordData: props.displayWordParams.wordData,
                                                    editDialogParams: {
                                                        type: "wordAdd",
                                                        header: "単語を追加し、品詞、意味、例文、例文の意味を追加します",
                                                        idMap: getElementIdByIndex(props.displayWordParams.wordData, "sentence", 0, 0),
                                                        isOpen: true
                                                    },
                                                    isEditted: false
                                                }
                                            }
                                        })
                                    }}
                                >
                                    編集
                                </Button>
                            </Box>
                        </Stack>
                        :
                        <>
                            <NotificationBar
                                params={{
                                    ...props.displayWordParams,
                                    selectedMeaningId
                                }}
                            />
                            <EditDialog 
                                displayWordParams={props.displayWordParams}
                                dispatch={props.dispatch}
                                imageRepo={imageRepo}
                                imageDispatch={imageDispatch}
                                userSentenceImages={userSentenceImages}
                                setRequestId={setRequestId}
                                selectedMeaningId={selectedMeaningId}
                                setSelectedMeaningId={setSelectedMeaningId}
                            />
                            <Box w="100%">
                                <form onSubmit={formik.handleSubmit}>
                                    <RadioGroup 
                                        onChange={(value) => {
                                            setSelectedMeaningId(value)
                                        }}
                                        value={selectedMeaningId ? selectedMeaningId : undefined}
                                    >
                                        <HStack>
                                            <Box
                                                flex="1"
                                                fontSize="4xl"
                                                mb="2px"
                                            >
                                                {props.displayWordParams.displayName}
                                            </Box>
                                            <Spacer />
                                            <Box
                                                textAlign="center"
                                                pb="4px"
                                                fontSize="xs"
                                            >
                                                <WordTags
                                                    displayWordParams={props.displayWordParams} 
                                                />
                                            </Box>                            
                                            {
                                                props.displayWordParams.mode === "readyToEditWordElement" ?
                                                    <HStack
                                                        mb="8px"
                                                    >
                                                        <Spacer/>
                                                        {
                                                            props.displayWordParams.isUserOriginWord ?
                                                                <EditIcon
                                                                    onClick={() => {
                                                                        props.dispatch({
                                                                            type: "displayWordEditDialogOpened",
                                                                            data: {
                                                                                previous:{
                                                                                    search: props.searchParams
                                                                                },
                                                                                current: {
                                                                                    wordData: props.displayWordParams.wordData,
                                                                                    editDialogParams: {
                                                                                        type: "pronunciationEdit",
                                                                                        header: "発音を変更します",
                                                                                        isOpen: true
                                                                                    },
                                                                                    isEditted: props.displayWordParams.isEditted
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            !props.displayWordParams.isNew ?
                                                                <DeleteIcon
                                                                    onClick={() => {
                                                                        props.dispatch({
                                                                            type: "displayWordEditDialogOpened",
                                                                            data: {
                                                                                previous:{
                                                                                    search: props.searchParams
                                                                                },
                                                                                current: {
                                                                                    wordData: props.displayWordParams.wordData,
                                                                                    editDialogParams: {
                                                                                        type: "wordDelete",
                                                                                        header: "単語を削除します。この単語に設定された全ての情報が削除されます。※削除すると復元することはできません",
                                                                                        isOpen: true
                                                                                    },
                                                                                    isEditted: props.displayWordParams.isEditted
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                :
                                                                <></>
                                                        }
                                                    </HStack>
                                                    :
                                                    <></>
                                            }
                                        </HStack>
                                        {
                                            props.displayWordParams.isRedirect ?
                                                <Box
                                                    m="0px 0px 8px 4px"
                                                
                                                >
                                                    ({props.displayWordParams.originalDisplayName} から転送)
                                                </Box>
                                                :
                                                <></>
                                        }

                                        <PosList
                                            displayWordParams={props.displayWordParams}
                                            searchParams={props.searchParams}
                                            dispatch={props.dispatch}
                                            imageRepo={imageRepo}
                                            userSentenceImages={userSentenceImages}
                                            setUserSentenceImages={setUserSentenceImages}
                                            setRequestId={setRequestId}
                                        />

                                        {
                                            props.displayWordParams.episode ?
                                                <>
                                                    <WordEpisode
                                                        displayName={props.displayWordParams.displayName}
                                                        imageUrl={props.displayWordParams.imageUrl}
                                                        episode={props.displayWordParams.episode}
                                                    />
                                                    {
                                                        isdebug ?
                                                            <Flex
                                                                m="10px 0px 10px 0px"
                                                            >
                                                                <Spacer/>
                                                                <Button
                                                                    fontSize="xs"
                                                                    height="24px"
                                                                    onClick={() => {
                                                                        navigator.clipboard.writeText(`D:\\Temp\\data\\episodeDebug\\2024-04-04\\${props.displayWordParams.wordId}.txt`)
                                                                    }}
                                                                >
                                                                    copy the path
                                                                </Button>
                                                                
                                                            </Flex>
                                                            :
                                                            <></>
                                                    }
                                                </>
                                                :
                                                <></>
                                        }
                                        
                                        <Stack>
                                            <FormControl pb="3">
                                                <FormLabel htmlFor="notes">メモ欄</FormLabel>
                                                <Textarea 
                                                    id="notes"
                                                    {...formik.getFieldProps("notes")}
                                                    style={{


                                                        "color": "#000",
                                                        "backgroundColor": "#fff"
                                                    }}
                                                />
                                            </FormControl>
                                            <Box
                                                height="28px"
                                                color="red"
                                                textAlign="left"
                                                ml="4"
                                                mb="3"
                                            >
                                                {
                                                    formik.touched.notes && formik.errors.notes ? 
                                                        formik.errors.notes
                                                        :
                                                        null 
                                                }
                                            </Box>      
                                        </Stack>                                              

                                        <HStack>
                                            <Button 
                                                type="submit"
                                                colorScheme="blue"
                                                isDisabled={                                                            // Unlock conditions
                                                    (!props.displayWordParams.isEditted                                 // 1) updated through EditDialog 
                                                    && !formik.dirty                                                    // 2) updated in Notes section
                                                    && props.displayWordParams.selectedMeaningId === selectedMeaningId  // 3) changed selection of the meaning
                                                    && !props.displayWordParams.isNew)                                  // 4) not yet registered document(=isNew) is searched
                                                                                                                        //
                                                    || !selectedMeaningId                                               // a) None of meanings is selected yet
                                                    || (props.displayWordParams.isNew && getTotalWordCount() >= getWordCountQuota())        // b) hitting a quote of the number of registed words
                                                }
                                            >
                                                保存
                                            </Button>
                                            <Button
                                                colorScheme="blue"
                                                isDisabled={props.displayWordParams.mode !== "browseWord" || formik.dirty}
                                                onClick={()=>{
                                                    props.dispatch({
                                                        type: "studyInit",
                                                        data: {
                                                            previous:{
                                                                search: props.searchParams,
                                                                scrollPosition: window.scrollY
                                                            },
                                                            current: {
                                                                wordData: props.displayWordParams.wordData
                                                            }
                                                        }
                                                    })
                                                }}
                                            >
                                                学習
                                            </Button>
                                        </HStack>

                                        <HStack>
                                            <Spacer/>
                                        </HStack>
                                        <HStack
                                            pb="10px"
                                        >
                                            <Spacer/>
                                            <Box>
                                                <HStack>
                                                    <Box>閲覧</Box>
                                                    <Switch 
                                                        size="md"
                                                        colorScheme="blue"
                                                        isChecked={["editWordElement", "readyToEditWordElement"].includes(props.displayWordParams.mode)}
                                                        onChange={(e)=> {

                                                            let type
                                                            if (["editWordElement", "readyToEditWordElement"].includes(props.displayWordParams.mode)) {
                                                                type = "displayWordSwitchToBrowse"
                                                            } else {
                                                                type = "displayWordSwitchToEdit"
                                                            }

                                                            props.dispatch({
                                                                type,
                                                                data: {
                                                                    previous:{
                                                                        search: props.searchParams
                                                                    },
                                                                    current: {
                                                                        wordData: props.displayWordParams.wordData,
                                                                        isEditted: props.displayWordParams.isEditted
                                                                    }
                                                                }
                                                            })
                                                        }}
                                                        disabled={props.displayWordParams.isEditted 
                                                            || formik.dirty
                                                            || !selectedMeaningId
                                                        }
                                                    />
                                                    <Box>編集</Box>
                                                </HStack>
                                            </Box>
                                        </HStack>
                                    </RadioGroup>
                                </form>
                            </Box>
                            <Box
                                width="100%"
                                m="0px 0px 20px 3px"
                            >
                                <BackButton
                                    dispatch={props.dispatch}
                                />
                            </Box>
                        </>
            }
        </Stack>
    )
}
  
export default DisplayWord;

