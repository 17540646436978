import React from "react";
import {
    Box,
    Button,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import {
    WarningTwoIcon
} from "@chakra-ui/icons"

const ErrorDialog = (props) => {

    const onClick = () => {
        props.onClose()
        props.dispatch({
            type: props.dispatchType,
            data: {
                previous: {},
                current: {}
            }
        })
    }

    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={props.isOpen} 
            onClose={props.onClose}
        >
            <ModalOverlay />
            <ModalContent
                maxW="340px"
            >
                <ModalHeader>
                    <WarningTwoIcon
                        m="0px 8px 4px 0px"
                        color="red"
                        fontSize="30px"
                    />
                    {props.errorMessage.title}
                </ModalHeader>
                <ModalCloseButton 
                    onClick={() => onClick()}
                />                
                <ModalBody>
                    <Box
                        fontSize="md"
                        justifyContent="flex-start"
                    >
                        {
                            props.errorMessage.texts.map((text) => {
                                return (<Text>{text}</Text>)
                            })
                        }
                    </Box>
                </ModalBody>
                <ModalFooter
                    justifyContent="center"
                >
                    <Button
                        colorScheme="gray"
                        onClick={() => onClick()}
                        >
                        閉じる
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>        
    )
}

export default ErrorDialog