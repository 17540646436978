import {useState} from "react";
import {useMsal} from '@azure/msal-react'
import {apiRequest} from "../authConfig"

const useSubmit = () => {
    const {instance} = useMsal()
    const [requestIds, setRequestIds] = useState({})
    const timeoutInMsec = 10000
    const maxRetryCount = 6

    const getBearerToken = async () => {
        //console.log("instance:" + JSON.stringify(instance))
        await instance.initialize()
        const account = instance.getActiveAccount()
        // if (!account) {
        //     throw Error(
        //         'No active account! Verify a user has been signed in and setActiveAccount has been called.'
        // )}
        
        if (account) {
            const response = await instance.acquireTokenSilent({
                ...apiRequest,
                account
            })
        
            return response.accessToken
        }

        return null
    }
  
    const submit = async (url, requestBody, requestId=null) => {

        const bearerToken = await getBearerToken()
        //console.log("bearerToken: " + bearerToken)
        let error = {}
        let jresponse = {}

        // try {
            if (requestId !== null) {
                setRequestIds({
                    ...requestIds,
                    [requestId]: true
                })
            }

            for (let retry = 0; retry < maxRetryCount; retry++) {

                const controller = new AbortController();
                const timeout = setTimeout(() => {
                    controller.abort()
                }, timeoutInMsec)

                try {
                    const response = await fetch(url, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${bearerToken}`
                        },
                        body: JSON.stringify(requestBody),
                        signal: controller.signal,
                        // mode: "cors",
                        // credentials: "include"
                    })
                    if (response.ok) {
                        if (requestId !== null) {
                            setRequestIds({
                                ...requestIds,
                                [requestId]: false
                            })
                        }
                        clearTimeout(timeout)
                        jresponse = await response.json()
                        return jresponse
                    } else {
                        throw new Error(`statsCode: ${response?.status}, message: ${response?.statusText}`);
                    }
                } catch (e) {
                    error = e
                } finally {
                    clearTimeout(timeout)
                    if (requestId !== null) {
                        setRequestIds({
                            ...requestIds,
                            [requestId]: false
                        })
                    }
                }
            }
        // } catch (e) {
        //     error = e
        // }

        jresponse = {
            status: "fail",
            statusCode: error && "statusCode" in error ? error.statusCode : -1,
            message: error && "message" in error ? error.message : "something went wrong."
        }
        return jresponse
    }

    const isLoading = (requestId) => {
        return requestId in requestIds ? requestIds[requestId] : null
    }

    return {
        apiEndPoint: apiRequest.url,
        isLoading,
        submit
    }
}
  
export default useSubmit;
