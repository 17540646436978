import React, { useState, useEffect } from "react";
import {
    Box,
    Stack,
    Text,
    Image,
    Center,
    Heading,
    Divider,
    Card,
    CardBody,
    useDisclosure
} from '@chakra-ui/react';
import { useCookies } from "react-cookie";
import SearchWord from './search/SearchWord';
import DisplayWord from './display/DisplayWord';
import useApi from "../hooks/useApi";
import FullCoverSpinner from "./FullCoverSpinner";
import SearchResultCandidate from "./search/SearchResultCandidate";
import LearningStatusCard from "./record/LearningStatusCard";
import { useUserContext } from "../context/userContext";
import ExpiringDialog from "./license/ExpiringDialog";
import { getLicenseExpirationCookieName } from "../CommonFunction";
import ContentCard from "./ContentCard";
import FeatureCard from "./FeatureCard";

import { MdOutlineQuiz, MdSaveAlt } from "react-icons/md";
import { PiCards, PiClockCounterClockwise, PiNotePencil } from "react-icons/pi";
import { LuMenuSquare } from "react-icons/lu";

// images for register word content 
import search from "../assets/screenshots/search.png"
import searchResult from "../assets/screenshots/searchResult.png"
import searchResult_partialMatch from "../assets/screenshots/searchResult_partialMatch.png"
import searchResult_ja from "../assets/screenshots/searchResult_ja.png"
import selectingMeaning from "../assets/screenshots/selectingMeaning.png"
import clickSave from "../assets/screenshots/clickSave.png"
import saveWord from "../assets/screenshots/savedWord.png"

// images for quiz content 
import topPageMenu from "../assets/screenshots/topPageMenu.png"
import quiz from "../assets/screenshots/quiz.png"
import quizCorrect from "../assets/screenshots/quizCorrect.png"
import quizIncorrect from "../assets/screenshots/quizIncorrect.png"
import quizTimeout from "../assets/screenshots/quizTimeout.png"
import quizResult from "../assets/screenshots/quizResult.png"
import quizDetail from "../assets/screenshots/quizDetail.png"

// images for study word content
import allowToUseMicrophone from "../assets/screenshots/allowToUseMicrophone.png"
import beforeStudyWord from "../assets/screenshots/beforeStudyWord.png"
import studyWord from "../assets/screenshots/studyWord.png"
import studyWord_step1_1 from "../assets/screenshots/studyWord_step1_1.png"
import studyWord_step1_3 from "../assets/screenshots/studyWord_step1_3.png"
import studyWord_step1_4 from "../assets/screenshots/studyWord_step1_4.png"
import studyWord_step2 from "../assets/screenshots/studyWord_step2.png"
import studyWord_step3 from "../assets/screenshots/studyWord_step3.png"
import studyWord_step4 from "../assets/screenshots/studyWord_step4.jpg"


const DefaultPage = (props) => {
    const {
        isLoading
    } = useApi();
    const {
        getDaysUntilExpire,
        getExpiredAt,
        getTotalWordCount,
        getTotalQuizCount,
        getIsFirstRun,
        getUserName,
        getTotalStudyTime
    } = useUserContext();
    ////////////////////////////////////////////////////
    useEffect(() => {
        const daysUntilExpire = getDaysUntilExpire();
        if (daysUntilExpire < 0) {
            props.dispatch({
                type: "wordList"
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    ////////////////////////////////////////////////////
    const [requestId, setRequestId] = useState(0);
    const {
        isOpen: isSpinnerOpen,
        onOpen: onSpinnerOpen,
        onClose: onSpinnerClose
    } = useDisclosure();
    useEffect(() => {
        if (requestId) {
            if (!isLoading(requestId) && !isSpinnerOpen) {
                onSpinnerOpen();
            }
        } else if (isSpinnerOpen) {
            onSpinnerClose();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestId]);
    ////////////////////////////////////////////////////
    //
    const [cookie] = useCookies();
    const cookieName = getLicenseExpirationCookieName(getExpiredAt());
    const daysUntilExpire = getDaysUntilExpire();
    const expiredAt = getExpiredAt();
    let dueDate = "";
    switch(daysUntilExpire) {
        case 0:
            dueDate = "明日";
            break;
        case 1:
            dueDate = "明後日";
            break;
        case 2:
            dueDate = "しあさって";
            break;
        default:
            break;
    }
    if (dueDate) {
        dueDate = `${dueDate} (${expiredAt.substring(0, 10)}) `;
    }
    const {
        isOpen: isExpiringOpen,
        onOpen: onExpiringOpen,
        onClose: onExpiringClose
    } = useDisclosure();
    useEffect(() => {
        if (requestId === null) {
            if (0 <= daysUntilExpire && daysUntilExpire <= 2) {
                if (!cookie?.[cookieName])
                onExpiringOpen();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestId]);
    ////////////////////////////////////////////////////
    return (
        <>
            <FullCoverSpinner
                isOpen={isSpinnerOpen}
                onClose={onSpinnerOpen}
            />
            <ExpiringDialog
                isOpen={isExpiringOpen}
                onOpen={onExpiringOpen}
                onClose={onExpiringClose}
                dueDate={dueDate}
            />
            <Stack
                textAlign="left"
                rounded="md"
                justifyContent="center"
                alignItems="center"
                p="1"
                mb="18px"
            >
                <SearchWord
                    query={props.query}
                    setRequestId={setRequestId}
                    dispatch={props.dispatch}
                    setLaunchSubApp={props.setLaunchSubApp}
                />
                {props.candidates ? (
                    <Stack
                        textAlign="left"
                        rounded="md"
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                    >
                        {props.hasPerfectMatch ? (
                            <></>
                        ) : (
                            <SearchResultCandidate
                                query={props.query}
                                candidates={props.candidates}
                                dispatch={props.dispatch}
                            />
                        )}
                        {Object.keys(props.selectedWordData).length > 0 ? (
                            <DisplayWord
                                dispatch={props.dispatch}
                                wordData={props.selectedWordData}
                                scrollPosition={0}
                                setLaunchSubApp={props.setLaunchSubApp}
                            />
                        ) : (
                            <></>
                        )}
                    </Stack>
                ) : (
                    <Stack justifyContent="center" alignItems="center">
                        {getTotalWordCount() === 0 ? (
                            <>
                                {getIsFirstRun() ? (
                                    <>
                                        <Card
                                            direction={{ base: 'column', sm: 'row' }}
                                            overflow='hidden'
                                            variant='outline'
                                            w={[350, 450, 650]}
                                            boxShadow="md"
                                            m="20px 0px 8px 0px"
                                            height="100%"
                                            borderColor="orange.300"
                                            borderWidth="2px"
                                        >
                                            <CardBody
                                                width="100%"
                                                p="8px 12px 8px 12px"
                                            >
                                                <Stack>
                                                    <Box>
                                                        {`${getUserName()} さん、はじめまして。`}<br/>
                                                        英活にユーザー登録いただきありがとうございます。<br/>
                                                        {`${getUserName()} さんが効率よく学習を進められるようお手伝いさせてください。`}<br/>
                                                        どうぞ宜しく致します。<br/>
                                                    </Box>
                                                    <Box
                                                        width="100%"
                                                        style={{ textAlign: 'right' }}
                                                    >
                                                        <Text
                                                            m="0px"
                                                        >
                                                            necolab
                                                        </Text>
                                                    </Box>
                                                </Stack>
                                            </CardBody>
                                        </Card>
                                        <Divider/>
                                    </>
                                ) : (
                                    <></>
                                )}
                                <ContentCard
                                    title="単語の登録方法"
                                    cardImage={
                                        <Box
                                            m="0px 0px 0px 0px"
                                            width="64px"
                                            color="#F6AD55"
                                        >
                                            <MdSaveAlt
                                                size="100%" 
                                            />
                                        </Box>
                                    }
                                    summary={
                                        <Box
                                            width="280px"
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="flex-start"
                                            m="0px 0px 4px 0px"                                            
                                        >
                                            <Box>1. 登録したい単語を検索します</Box>
                                            <Box>2. 記憶したい意味を選択します</Box>
                                            <Box>3. 保存ボタンを押します</Box>
                                        </Box>
                                    }
                                    details={
                                        <>
                                            <Text>
                                                英活は登録された単語をクイズ・学習等の対象として扱います。<br/>
                                                ここでは単語の登録方法について説明します。
                                            </Text>
                                            <Heading
                                                size="sm"
                                            >
                                                1. 登録したい単語を検索する
                                            </Heading>
                                            <Text>画面最上部の検索ボックスに自分が登録したい単語をタイプし、[検索]ボタンをクリックします。</Text>
                                            <Center>
                                                <Image
                                                    src={search}
                                                    border="1px solid #90cdf4"
                                                    width="320px"
                                                    alt="英活・検索"
                                                />
                                            </Center>
                                            <br/>
                                            <Text>入力された文字列と完全一致した場合、その単語のページが表示されます。</Text>                                            
                                            <Center>
                                                <Image
                                                    src={searchResult}
                                                    border="1px solid #90cdf4"
                                                    width="320px"
                                                    alt="英活・検索結果"
                                                />
                                            </Center>
                                            <br/>
                                            <Text>
                                                入力された文字列と完全一致する単語が辞書にない場合、入力された文字列に近いスペルの英単語が候補として表示されます。<br/>
                                                候補の中に登録したい単語がある場合、その単語をクリックするとその単語のページが表示されます。
                                                ※辞書にない単語を登録することもできますが、その方法は別途説明します。
                                            </Text>                                            
                                            <Center>
                                                <Image
                                                    src={searchResult_partialMatch}
                                                    border="1px solid #90cdf4"
                                                    width="320px"
                                                    alt="英活・部分一致検索結果"
                                                />
                                            </Center>
                                            <br/>
                                            <Text>
                                                日本語で検索した場合、入力された文字列に近い意味を持つ英単語が候補として表示されます。<br/>
                                                候補の中に登録したい単語がある場合、その単語をクリックするとその単語のページが表示されます。
                                            </Text>                                            
                                            <Center>
                                                <Image
                                                    src={searchResult_ja}
                                                    border="1px solid #90cdf4"
                                                    width="320px"
                                                    alt="英活・日本語検索結果"
                                                />
                                            </Center>
                                            <br/>
                                            <Heading
                                                size="sm"
                                            >
                                                2. 単語の意味の選択
                                            </Heading>
                                            <Text>
                                                辞書に登録されている多くの単語は複数の意味、複数の品詞を持っています。複数の意味を持つ単語を表示している場合、どの意味で記憶するかを選択してから単語を登録する必要があります。各単語の意味の下に「この意味で記憶する」のラジオボタンがあるので、どれか１つを選択します。<br/>
                                                なお１つの単語には１つの意味しか選択することはできません。
                                            </Text>
                                            <Center>
                                                <Image
                                                    src={selectingMeaning}
                                                    border="1px solid #90cdf4"
                                                    width="320px"
                                                    alt="英活・部分一致検索結果選択"
                                                />
                                            </Center>
                                            <br/>
                                            <Text>※表示されている単語に１つの意味しか定義されていない場合には意味選択オプションは表示されません。</Text>
                                            <br/>
                                            <Heading
                                                size="sm"
                                            >
                                                3. 単語の登録
                                            </Heading>
                                            <Text>
                                                画面一番したまでスクロールして [保存] ボタンをクリックします。
                                            </Text>
                                            <Center>
                                                <Image
                                                    src={clickSave}
                                                    border="1px solid #90cdf4"
                                                    width="320px"
                                                    alt="英活・単語保存"
                                                />
                                            </Center>
                                            <br/>
                                            <Text>
                                                暫く待つと単語が登録されます。選択した意味が最上部に表示されます。
                                            </Text>
                                            <Center>
                                                <Image
                                                    src={saveWord}
                                                    border="1px solid #90cdf4"
                                                    width="320px"
                                                    alt="英活・単語保存後"
                                                />
                                            </Center>
                                            <br/>
                                        </>
                                    }
                                />
                            </>) :
                            (
                                <>
                                    {getTotalQuizCount() === 0 ? (
                                        <ContentCard
                                            title="クイズの使い方"
                                            cardImage={
                                                <Box
                                                    m="0px 0px 0px 0px"
                                                    width="64px"
                                                    color="#F6AD55"
                                                >
                                                    <MdOutlineQuiz
                                                        size="100%" 
                                                    />
                                                </Box>
                                            }
                                            summary={
                                                "登録された単語の理解度をクイズを通して確認することができます。ここではクイズ機能の使用方法について説明します"
                                            }
                                            details={
                                                <>
                                                    <Heading
                                                        size="sm"
                                                    >
                                                        1. クイズに移動します
                                                    </Heading>
                                                    <Text>
                                                        画面トップ左のメニューボタン (<span style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '1.3em' }}><LuMenuSquare /></span>) をクリックし "クイズ" を選択してください。<br/>
                                                        またはトップページ中央の [単語クイズ] ボタンからも移動できます。
                                                    </Text>
                                                    <Center>
                                                        <Image
                                                            src={topPageMenu}
                                                            border="1px solid #90cdf4"
                                                            width="320px"
                                                            alt="英活トップページ"
                                                        />
                                                    </Center>
                                                    <br/>
                                                    <Heading
                                                        size="sm"
                                                    >
                                                        2. クイズが始まります
                                                    </Heading>
                                                    <Text>
                                                        表示されている英単語の意味を[１]～[４]の中から選択してください。分からない場合は[５]を選択してください。
                                                    </Text>
                                                    <Center>
                                                        <Image
                                                            src={quiz}
                                                            border="1px solid #90cdf4"
                                                            width="320px"
                                                            alt="英活・クイズ"
                                                        />
                                                    </Center>
                                                    <br/>
                                                    <Text>
                                                        正解した場合、その回答に関する理解度を「自信あり」「そこそこ」「自信ない」の中からどれか選択してクリックしてください。<br/>
                                                        あまり深く悩まず、直感的にかつ正直に選択して大丈夫です。<br/>
                                                        この理解度の情報を元に、次回出題スケジュールが適切に計算されます。
                                                    </Text>
                                                    <br/>                                                
                                                    <Center>
                                                        <Image
                                                            src={quizCorrect}
                                                            border="1px solid #90cdf4"
                                                            width="320px"
                                                            alt="英活・クイズ理解度"
                                                        />
                                                    </Center>
                                                    <br/>
                                                    <Text>
                                                        不正解の場合は自動的に次の問題に遷移します。
                                                    </Text>
                                                    <br/>
                                                    <Center>
                                                        <Image
                                                            src={quizIncorrect}
                                                            border="1px solid #90cdf4"
                                                            width="320px"
                                                            alt="英活・クイズ不正解"
                                                        />
                                                    </Center>
                                                    <br/>
                                                    <Text>
                                                        １５秒以内に回答がなかった場合は時間切れで不正解となります。<br/>
                                                        もし何かの都合でクイズを中断したい場合は、画面左下の [戻る] ボタンをクリックするとクイズが終了できます。<br/>
                                                        そのまま放置すると残りのクイズ問題は全て時間切れで不正解扱いになってしまいますのでご注意ください。                                                    
                                                    </Text>                                                
                                                    <br/>
                                                    <Center>
                                                        <Image
                                                            src={quizTimeout}
                                                            border="1px solid #90cdf4"
                                                            width="320px"
                                                            alt="英活・クイズ時間切れ"
                                                        />
                                                    </Center>
                                                    <br/>
                                                    <Heading
                                                        size="sm"
                                                    >
                                                        3. クイズの結果を確認しましょう
                                                    </Heading>
                                                    <Text>
                                                        規定数のクイズの出題および回答が終わると、クイズの結果が発表されます。<br/>
                                                        正解率と合わせて、出題された全ての単語の正解・不正解の結果と意味がリスト表示されます。
                                                    </Text>
                                                    <Center>
                                                        <Image
                                                            src={quizResult}
                                                            border="1px solid #90cdf4"
                                                            width="320px"
                                                            alt="英活・クイズ結果"
                                                        />
                                                    </Center>
                                                    <br/>
                                                    <Text>
                                                        リストの単語をクリックすると、その単語のサマリーが表示されます。<br/>
                                                        [詳細] をクリックすると、単語の詳細ページに遷移します。
                                                    </Text>
                                                    <Center>
                                                        <Image
                                                            src={quizDetail}
                                                            border="1px solid #90cdf4"
                                                            width="320px"
                                                            alt="英活・クイズ結果"
                                                        />
                                                    </Center>
                                                    <br/>                                                
                                                </>
                                            }
                                        />
                                    ) : (
                                        <></>
                                    )}

                                    {getTotalStudyTime() === 0 ? (
                                        <ContentCard
                                        title="学習機能の使い方"
                                        cardImage={
                                            <Box
                                                m="0px 0px 0px 0px"
                                                width="64px"
                                                color="#F6AD55"
                                            >
                                                <PiNotePencil
                                                    size="100%" 
                                                />
                                            </Box>
                                        }
                                        summary={
                                            "登録した単語を学習することができます。実際にその単語を使って学習することは、記憶定着を促進する効果的な方法であることが広く実証されています。"
                                        }
                                        details={
                                            <>
                                                <Heading
                                                    size="sm"
                                                >
                                                    1. 学習したい単語の閲覧ページに移動します
                                                </Heading>
                                                <Text>
                                                    単語の閲覧ページへ移動するには以下の様にいろいろな方法があります。<br/>
                                                    最も手軽で習慣付けしやすい方法は、単語登録時に [保存] ボタンをクリックして、直ぐにその隣りの [学習] ボタンをクリックして学習を開始することです。<br/>                                                </Text>
                                                <br/>
                                                <Center>
                                                    <Image
                                                        src={beforeStudyWord}
                                                        border="1px solid #90cdf4"
                                                        width="320px"
                                                        alt="英活・学習ページに移動"
                                                    />
                                                </Center>
                                                <br/>
                                                <Text>
                                                    その他にも閲覧ページに行くには以下のようないろいろな方法があります。<br/>
                                                    1. 検索ボックスからその単語で検索<br/>
                                                    2. 単語帳のページから目的の単語を探してクリックして [詳細] ボタンをクリック<br/>
                                                    3. 履歴ページから登録した単語を探してクリックして、[詳細] ボタンをクリック<br/>
                                                    4. クイズ結果のページの単語リストをクリックして、[詳細] ボタンをクリック<br/>
                                                    <br/>
                                                    ※登録していない単語は学習対象になりません。単語を保存すると登録され、[学習] ボタンが有効になります。
                                                    <br/>

                                                </Text>
                                                
                                                <Heading
                                                    size="sm"
                                                >
                                                    2. 学習方法
                                                </Heading>
                                                <Text>
                                                    閲覧ページをスクロールして一番下の [学習] ボタンをクリックし、単語の学習ページに移動します。<br/>
                                                    学習は通常 Step1～4 の構成になっています。(※ユーザーが新規作成した単語など、4ステップ構成ではない場合があります)<br/>                                                    
                                                    <br/>
                                                    以下に各ステップの使い方に関して説明します。<br/>
                                                </Text>
                                                <Center>
                                                    <Image
                                                        src={studyWord}
                                                        border="1px solid #90cdf4"
                                                        width="320px"
                                                        alt="英活・学習"
                                                    />
                                                </Center>
                                                <br/>
                                                <Heading
                                                    size="sm"
                                                >
                                                    2.1 Step1: 発音を聞きリピートしましょう
                                                </Heading>
                                                <Text>
                                                    ① 最初にお手本となる発音を再生しリスニングします。赤のスピーカーボタンは女性の声、青のボタンは男性の声で再生されます。 <br/>
                                                    ② 次にその下のマイクのアイコンをクリックしてください。<br/>
                                                </Text>
                                                <br/>
                                                <Center>
                                                    <Image
                                                        src={studyWord_step1_1}
                                                        border="1px solid #90cdf4"
                                                        width="320px"
                                                        alt="英活・学習ステップ１"
                                                    />
                                                </Center>
                                                <br/>
                                                <Text>
                                                    マイクのアイコン初回クリック時には以下のようなマイクの使用許可を求めるメッセージが表示されますので、マイクの使用を許可してください。<br/>
                                                </Text>
                                                <br/>
                                                <Center>
                                                    <Image
                                                        src={allowToUseMicrophone}
                                                        border="1px solid #90cdf4"
                                                        width="320px"
                                                        alt="英活・マイクの使用許可"
                                                    />
                                                </Center>
                                                <br/>
                                                <Text>
                                                    ③ マイクに向かって単語を発音してください。発音が終わったらバーの一番左の [Save] ボタンをクリックしてください。<br/>
                                                </Text>
                                                <Center>
                                                    <Image
                                                        src={studyWord_step1_3}
                                                        border="1px solid #90cdf4"
                                                        width="320px"
                                                        alt="英活・マイクの使用許可"
                                                    />
                                                </Center>
                                                <br/>
                                                <Text>
                                                    ④ 発音の録音が成功するとマイクのアイコンの下に再生マークのアイコンが表示されます。再生マークのアイコンをクリックして、ご自身の発音を再生してください。お手本の発音と聞き比べて自分の発音を確認するのはよい練習になります。<br/>
                                                    録音しなおしたい場合はマイクのアイコンを再度クリックすると再録音されます。
                                                </Text>
                                                <Center>
                                                    <Image
                                                        src={studyWord_step1_4}
                                                        border="1px solid #90cdf4"
                                                        width="320px"
                                                        alt="英活・学習ステップ１"
                                                    />
                                                </Center>
                                                <br/>
                                                <Text>
                                                    ステップが完了すると Step1 の右のアイコンが青のチェックマークに変わります。
                                                </Text>
                                                <br/>
                                                <Heading
                                                    size="sm"
                                                >
                                                    2.2 Step2: 単語を正確に記述しましょう
                                                </Heading>
                                                <Text>
                                                    単語をスペルミスなく正確にタイプしてください。間違えたら訂正してタイプしなおしてください。
                                                </Text>
                                                <br/>                                      
                                                <Center>
                                                    <Image
                                                        src={studyWord_step2}
                                                        border="1px solid #90cdf4"
                                                        width="320px"
                                                        alt="英活・学習ステップ２"
                                                    />
                                                </Center>
                                                <br/>
                                                <Heading
                                                    size="sm"
                                                >
                                                    2.3 Step3: 例文を聴きリピートしましょう
                                                </Heading>
                                                <Text>
                                                    Step1 と同じ要領で、その単語を含む例文をリスニング、リピートしてください。
                                                </Text>
                                                <br/>                                      
                                                <Center>
                                                    <Image
                                                        src={studyWord_step3}
                                                        border="1px solid #90cdf4"
                                                        width="320px"
                                                        alt="英活・学習ステップ３"
                                                    />
                                                </Center>
                                                <br/>
                                                <Heading
                                                    size="sm"
                                                >
                                                    2.4 Step4: "単語" を使って例文を英語にしてみましょう
                                                </Heading>
                                                <Text>
                                                    [例文] に表示されている日本語の例文を、その単語を使用して英文に翻訳してください。<br/>
                                                    英文が完成したら、その下にある [送信] ボタンをクリックしてください。<br/>
                                                    AIによって英文が添削されます。アドバイスを読み、その単語の使い方や英作文方法への理解を深めてください。<br/>
                                                </Text>
                                                <br/>                                      
                                                <Center>
                                                    <Image
                                                        src={studyWord_step4}
                                                        border="1px solid #90cdf4"
                                                        width="320px"
                                                        alt="英活・学習ステップ４"
                                                    />
                                                </Center>
                                                <br/> 
                                                <Text>
                                                    全てのステップに青のチェックマークついたら学習完了です。<br/>
                                                    [学習完了] ボタンをクリックすると閲覧ページに戻ることができます。
                                                </Text>
                                                <Text>
                                                    ※学習完了前に [戻る] ボタンなどを使って別のページに移動すると、学習記録は保存されず最初からやり直しになりますのでご注意ください。<br/>
                                                    ※ページ下部右側に３分間のカウントダウンタイマーがありますが、これはあくまで目訳としてお使いください。３分以上掛かっても何も問題ありません。<br/>
                                                    このタイマーは、記憶作業は１つのものにじっくり時間を掛けて覚えるより、短時間の記憶を繰り返し行ったほうが記憶定着効率がよいことから設置しています。
                                                </Text>
                                                <br/>                                                
                                            </>
                                        }
                                    />
                                    ) : (
                                        <></>
                                    )}


                                    {getTotalQuizCount() === 0 || getTotalStudyTime() === 0 ?
                                        <Divider/>
                                        :
                                        <></>
                                    }

                                    {getTotalQuizCount() > 0 ?
                                        <LearningStatusCard
                                                setRequestId={setRequestId}
                                        />
                                        :
                                        <></>
                                    }

                                    <FeatureCard
                                        onClick={props.launchQuiz}
                                        icon={<MdOutlineQuiz size="100%" />}
                                        title={"単語クイズ"}
                                        summary={"クイズで効率よく反復記憶しましょう"}
                                    />
                                    <FeatureCard
                                        onClick={props.launchWordList}
                                        icon={<PiCards size="100%" />}
                                        title={"単語帳"}
                                        summary={"単語を素早くチェックしましょう"}
                                    />
                                    <FeatureCard
                                        onClick={props.launchWordHistory}
                                        icon={<PiClockCounterClockwise size="100%" />}
                                        title={"履歴"}
                                        summary={"学習した単語を振り返ってみましょう"}
                                    />
                                </>
                            )
                        }
                    </Stack>
                )}
            </Stack>
        </>
    );
}
export default DefaultPage;
