import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom"

import { AlertProvider } from "./context/alertContext";
import { UserProvider } from "./context/userContext";

import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import 'bootstrap/dist/css/bootstrap.min.css';

import { CookiesProvider } from 'react-cookie';
import { initFacebookSdk } from './facebookSdk';


/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});

initFacebookSdk().then(() => {
    const root = ReactDOM.createRoot(document.getElementById('root'));

    const AppWrapper = process.env.REACT_APP_ENV === "INT" ? (
        <React.StrictMode>
            <BrowserRouter>
                <UserProvider>
                    <AlertProvider>
                        <CookiesProvider>
                            <App instance={msalInstance} />
                        </CookiesProvider>
                    </AlertProvider>
                </UserProvider>
            </BrowserRouter>
        </React.StrictMode>
    ) : (
        <BrowserRouter>
            <UserProvider>
                <AlertProvider>
                    <CookiesProvider>
                        <App instance={msalInstance} />
                    </CookiesProvider>
                </AlertProvider>
            </UserProvider>
        </BrowserRouter>
    );

    root.render(AppWrapper);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
