import React, { useState, useEffect }  from "react";
import { Navbar } from 'react-bootstrap';
import AccountDialog from "./AccountDialog";
import {
    Box,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,    
} from '@chakra-ui/react'
import {
    HamburgerIcon
} from "@chakra-ui/icons"
import { MdOutlineQuiz } from "react-icons/md"
import { PiCards, PiClockCounterClockwise } from "react-icons/pi"
import { AiOutlineHome } from "react-icons/ai"

import {useUserContext} from "../context/userContext"


const NavigationBar = (props) => {
    const {
        getTotalWordCount
    } = useUserContext()

    const [menuDisable, setMenuDisable] = useState(
        window.location.href !== "/"
        || !getTotalWordCount() 
        || getTotalWordCount() === 0
    )

    useEffect(() => {
        setMenuDisable(
            window.location.pathname !== "/"
            || !getTotalWordCount() 
            || getTotalWordCount() === 0
        )
        console.log(`window.location.pathname:${window.location.pathname}`)
        console.log(`getTotalWordCount()=${getTotalWordCount()}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const gradientStyle = {
        background: 'linear-gradient(to right, #4299e1, #00B5D8)'
    };

    return (
        <>
            <Navbar 
                bg="primary"
                variant="dark"
                className="navbarStyle"
                collapseOnSelect
                style={gradientStyle}
            >
                <Menu>
                    <MenuButton
                        m="0px 0px 0px 8px"
                        color="white"
                        as={IconButton}
                        aria-label='Options'
                        icon={<HamburgerIcon />}
                        variant='outline'                    
                    />
                    {
                        props.signInStatus === "signedIn" ?
                            <MenuList
                                width="108px"
                                minW="108px"
                            >
                                <MenuItem
                                    onClick={()=> {
                                        if (window.location.href === "/") {
                                            props.setLaunchSubApp("toHome")
                                        } else {
                                            window.location.href="/"
                                        }
                                    }}
                                >
                                    <AiOutlineHome />&nbsp;ホーム
                                </MenuItem>
                                <MenuItem
                                    onClick={()=> props.setLaunchSubApp("toQuiz")}
                                    isDisabled={menuDisable}
                                >
                                    <MdOutlineQuiz />&nbsp;クイズ
                                </MenuItem>
                                <MenuItem
                                    onClick={()=> props.setLaunchSubApp("toWordList")}
                                    isDisabled={menuDisable}
                                >
                                    <PiCards />&nbsp;単語帳
                                </MenuItem>
                                <MenuItem
                                    onClick={()=> props.setLaunchSubApp("toWordHistory")}
                                    isDisabled={menuDisable}
                                >
                                    <PiClockCounterClockwise />&nbsp;履歴
                                </MenuItem>
                            </MenuList>
                            :
                            <></>
                    }
                </Menu>
                <Box
                    m="0px 0px 0px 12px"
                    color="white"
                    fontSize="20px"
                >
                    英活
                </Box>
                <AccountDialog /> 
            </Navbar>
        </>
        // <Box
        //     width="100%"
        //     height="40px"
        //     bgColor="blue.500"
        // >
        //     <HStack>
        //         <Link
        //             href="/"
        //             color="white"
        //         >
        //             単語アプリ！
        //         </Link>
        //         <Box
        //             flex="1"
        //         >
        //             <AccountDialog />
        //         </Box>
        //     </HStack>
        // </Box>
    );
};

export default NavigationBar

