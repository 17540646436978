import Platform from "react-platform-js"

export function normalizeString(text) {
    if (text) {
        return text.normalize("NFKC")
            .replace(/[～ー~]/g, "-")
            .replace(/[\\.,]/, " ")
            .replace(/\s+/, " ")
            .trim()
            .toLowerCase()
    } else {
        return ""
    }
}

export function shuffleArray(array) {
    const cloneArray = [...array]

    for (let i = cloneArray.length - 1; i >= 0; i--) {
        const r = Math.floor(Math.random() * (i + 1))
        const dummy = cloneArray[i]
        cloneArray[i] = cloneArray[r]
        cloneArray[r] = dummy
    }
    // for (let i = 0; i < cloneArray.length; ++i) {
    //     console.log("shuffleArray.cloneArray[i]: " + cloneArray[i])        
    // }

    return cloneArray
}

export function randomPickupItems(array, count) {
    const cloneArray = [...array]
    const items = []

    while (cloneArray.length > 0 && count > 0) {
        const r = Math.floor(Math.random() * (cloneArray.length))
        if (!cloneArray[r]) {
            continue
        }
        items.push(cloneArray[r])
        // console.log("randomPickupItems.cloneArray[r]: " + cloneArray[r])
        delete cloneArray[r]
        --count
    }

    return items
}

export function maskWord(text) {
    let maskedText = ""
    
    Array.prototype.forEach.call(text, (c, i) => {
    if (i === 0) {
        maskedText = c
    } else if (c === ' ' || c === '\t') {
        maskedText += " "
    } else {
        maskedText += "*"
    }
    })

    return maskedText
}

export function convertDateInIso8601Jst(dt) {
    return dt.toLocaleDateString('sv-SE', { timeZone: 'Asia/Tokyo' }).replace(/[^0-9\-: ]/g, "") + "T00:00:00+09:00"
}

export function getTodayInIso8601Jst() {
    return convertDateInIso8601Jst(new Date())
}

export function convertDateTimeInIso8601Jst(dt) {
    return dt.toLocaleString('sv-SE', { timeZone: 'Asia/Tokyo' }).replace(/[^0-9\-: ]/g, "").replace(" ", "T") + "+09:00"
}

export function getNowInIso8601Jst() {
    return convertDateTimeInIso8601Jst(new Date())
}

export function getMediaUrl() {
    return process.env.REACT_APP_MEDIA_URL
}

export function getSases() {
    return {
        "mastervocabulary": process.env.REACT_APP_MASTER_VOCABULARY_SAS
    }
}

const jaDays = ["日", "月", "火", "水", "木", "金", "土"]

export function parseDateTimeStringToJaDate(dtString) {
    try {
        const dt = new Date(Date.parse(dtString))
        return `${dt.getFullYear()}年${dt.getMonth() + 1}月${dt.getDate()}日(${jaDays[dt.getDay()]})`
    } catch (error) {
        console.log("Exception in parseDateTimeStringToJaDate", error)
        return null
    }
}

export function parseDateTimeStringToJaTime(dtString) {
    try {
        const dt = new Date(Date.parse(dtString))
        return `${('0' + dt.getHours()).slice(-2)}:${('0' + dt.getMinutes()).slice(-2)}`
    } catch (error) {
        console.log("Exception in parseDateTimeStringToJaTime", error)
        return null
    }
}

export function parseDateTimeStringToDayColor(dtString) {
    try {
        const dt = new Date(Date.parse(dtString))
        const day = dt.getDay()
        if (day === 0) {
            return "red.500"
        } else if (day === 6) {
            return "blue.500"
        } else {
            return "black"
        }
    } catch (error) {
        console.log("Exception in parseDateTimeStringToColor", error)
        return null
    }
}

export function parseDateTimeStringToJaShortDate(dtString) {
    try {
        const dt = new Date(Date.parse(dtString))
        return `${dt.getFullYear()}/${('0' + (dt.getMonth() + 1)).slice(-2)}/${('0' + dt.getDate()).slice(-2)}`
    } catch (error) {
        console.log("Exception in parseDateTimeStringToJaDate", error)
        return null
    }
}


export function getAllowedIncorrectCount() {
    return 3
}

/* 0から100の値に基づいて色を計算する関数 */
export function calcColorByScore(score) {
    if (score >= 80) {
        return "blue.300"
    } else if (score >= 50) {
        return "yellow.400"
    } else if (score >= 30) {
        return "orange.400"
    } else {
        return "red.500"
    }
}

export function convertToPercentage(value, decimalPlace=1) {
    let p = String(Math.round(value * Math.pow(10, (decimalPlace + 2))) / Math.pow(10, decimalPlace))
    let index = p.indexOf(".")
    if (index < 0) {
        index = p.length
        p += "."
    }
    p += "0".repeat(decimalPlace)

    return p.substring(0, index + decimalPlace + 1) + " %"
}

export function getElapsedTime(dtString) {
    // unit: millisecond
    return (new Date()).getTime() - (new Date(Date.parse(dtString))).getTime()
}

export function areEquivalantArrays(array0, array1) {
    if (array0.length !== array1.length) {
        return false
    } else {
        for (let i = 0; i < array0.length; ++i) {
            if (array0[i] !== array1[i]) {
                return false
            }
        }
        return true
    }
}

export function getLicenseExpirationCookieName(expiredAt) {
    return `license_expiration_${expiredAt.substring(0, 10)}`
}

const browserLatestVersions = [
    { name: 'ie', latestVersion: -1 },
    { name: 'edge', latestVersion: 116 },
    { name: "chrome", latestVersion: 129 },
    { name: "firefox", latestVersion: 117 },
    { name: "safari", latestVersion: 17 },
    { name: "mobile safari", latestVersion: 17 }
]; 

export function getUserAgentInfo() {

    let isSupported = false
    const os = Platform.OS.toLowerCase() // "ios"
    const browserName = Platform.Browser.toLowerCase()
    const browserVersion = Platform.BrowserVersion
    const browserMajorVersion = parseInt(browserVersion.split('.')[0], 10)

    for (let i = 0; i < browserLatestVersions.length; ++i) {
        if (browserName === browserLatestVersions[i].name) {
            if (browserName !== "ie"
                    && browserMajorVersion >= browserLatestVersions[i].latestVersion - 1) {
                isSupported = true
            }
            break
        }
    }

    const userAgentInfo = {
        isSupported,
        os,
        browserName,
        browserVersion
    }

    console.log(`userAgentInfo: ${JSON.stringify(userAgentInfo)}`)

    return userAgentInfo
}

export function removeLinkTags(text) {

    if (!text) {
        return ""
    }
    
    const texts = []

    const pattern = RegExp('<link redirectWordId="(?<redirectWordId>[^"]+)">(?<linkedText>[^<]*)</link>', 'g')
    let match = pattern.exec(text);
    while (match) {
        texts.push(text.slice(0, match.index))
        texts.push(match.groups.linkedText)
        text = text.slice(match.index + match[0].length);
        match = pattern.exec(text);
    }

    if (text) {
        texts.push(text)
    }

    return texts.join("")
}