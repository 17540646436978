import { wordDB } from "../database/wordDB"
import { imageDB } from "../database/imageDB"

import useSubmit from "./useSubmit";
import {
    randomPickupItems,
    parseDateTimeStringToJaDate,
    parseDateTimeStringToJaTime,
    parseDateTimeStringToDayColor,
    getElapsedTime,
    getNowInIso8601Jst,
    getTodayInIso8601Jst,
    convertDateInIso8601Jst,
    areEquivalantArrays
} from "../CommonFunction"
import {
    cleanWordData,
    getValueFromWord,
    permutateWordElements,
    setValueToWord,
    extractUserOriginMeanings
} from "../WordDataFunction"
import { useUserContext } from "../context/userContext"


const useApi = () => {
    const {apiEndPoint, isLoading, submit} = useSubmit()
    const {
        setUserInfo,
        setUserStatus,
        hasUserStatusFetched,
        setUserSettings,
        getMaxQuizCount,
        getUserId,
        cleanOldItemsFromDBs,
        setTotalSentenceImageCount
    } = useUserContext()

    const registerUser = async (email, displayName) => {
        
        await cleanOldItemsFromDBs()
        let response = null

        const command = "registerUser"
        try {
            response = await submit(
                apiEndPoint,
                {
                    command,
                    parameters: [email, displayName]
                }
            )

            if (response.status === "success") {
                setUserInfo({
                    ...response.items[0],
                    settings: {
                        ...response.items[0]?.settings,
                        ...response.results
                    }
                })
            }
        } catch (error) {
            console.error(`ERROR in "${command}"`, error)
        }

        return response
    }

    const retrieveUser = async () => {

        await cleanOldItemsFromDBs()
        let response = null

        const command = "retrieveUser"
        try {
            response = await submit(
                apiEndPoint,
                {
                    command,
                    parameters: []
                }
            )

            if (response.status === "success") {
                setUserInfo({
                    ...response.items[0],
                    settings: {
                        ...response.items[0]?.settings,
                        ...response.results
                    }
                })
            }
        } catch (error) {
            console.error(`ERROR in "${command}"`, error)
        }

        return response
    }    

    const setAvatarImage = async (imageData) => {
        const id = getUserId()
        const command = "setAvatarImage"
        try {
            const response = await submit(
                apiEndPoint,
                {
                    command,
                    parameters: [imageData]
                }
            )

            if (response.status === "success") {
                await imageDB.items.put({
                    id,
                    image: imageData,
                    updateTime: getNowInIso8601Jst()
                })
                return true
            }
        } catch (error) {
            console.error(`ERROR in ${command}`, error)
            return false
       }
       return false
    }

    const getAvatarImage = async (forceUpdate=false) => {
        const id = getUserId()
        const command = "getAvatarImage"
        if (!forceUpdate) {
            const item = await imageDB.items.get(id)
            if (item && getElapsedTime(item.updateTime) < 24 * 3600 * 1000) {
                return item.image
            }
        }

        try {
            const response = await submit(
                apiEndPoint,
                {
                    command,
                    parameters: []
                }
            )

            if (response.status === "success" && response.items.length > 0) {
                const base64data = response.items[0]
                await imageDB.items.put({
                    id,
                    image: base64data,
                    updateTime: getNowInIso8601Jst()
                })

                return base64data
            }
        } catch (error) {
            console.error(`ERROR in ${command}`, error)
        } finally {
            return null
        }
    }

	const getWordStatus = async () => {
        if (hasUserStatusFetched()) {
            return true
        } else {
            try {
                const response = await submit(
                    apiEndPoint, 
                    {
                        command: "getWordStatus",
                        parameters: []
                    }
                )
        
                if (response.status === "success") {
                    setUserStatus(response.results)
                    return true
                } else {
                    return false
                }
        
            } catch (error) {
                console.error("ERROR in getWordStatus", error)
                return false
            }
        }
	}

    const getQuizWords = async (command="getQuizWords", requestId=null) => {
        try {
            const response = await submit(
                apiEndPoint, 
                {
                    command,
                    parameters: [getMaxQuizCount()]
                },
                requestId
            )

            if (response.status === "success") {
                let wordsData = randomPickupItems(response.items, response.items.length)
                // //console.log(`wordsData: ${JSON.stringify(wordsData)}`)
                // const selectedMeaningSentences = chooseSelectedMeaningSentences(wordsData)
                // console.log(`selectedMeaningSentences: ${JSON.stringify(selectedMeaningSentences)}`)

                return wordsData
            }
            else {
                console.log(`response: ${JSON.stringify(response)}`)
                return null
            }
        } catch (error) {
            console.error(`ERROR in ${command}`, error)
            return null
        }
    }

    const getQuizWordsRandomly = async () => {
        return await getQuizWords("getQuizWordsRandomly")
    }           

    const searchWord = async (query, requestId=null) => {
        const command = "searchWord"
        try {
            const response = await submit(
                apiEndPoint, 
                {
                    command,
                    parameters: [query]
                },
                requestId
            )

            if (response.status === "success") {
                let wordId = null
                let wordData = {}
                response.items.forEach((item) => {
                    if (item.matchStatus === "perfect") {
                        wordId = item.wordId
                        item.isSelected = true
                    } else {
                        item.isSelected = false
                    }
                })

                if (wordId) {
                    wordData = await readWordByWordId(wordId, query)
                }

                return {
                    selectedWordData: wordData,
                    candidates: response.items
                }
            }
            else {
                return null
            }
        } catch (error) {
            console.error(`ERROR in ${command}`, error)
            return null
        }
    }
    
    const commitActivity = async (wordId, cId, activity, displayName, selectedMeaning, options={}) => {
        try {
            const response = await submit(
                apiEndPoint, 
                {
                    command: "commitActivity",
                    parameters: [
                        wordId,
                        cId,
                        activity,
                        displayName,
                        selectedMeaning,
                        JSON.stringify(options)
                    ]
                }
            )            

            return response.status === "success"

        } catch (error) {
            console.error("ERROR in commitActivity", error)
            return false
        }
    }

    const upsertWord = async (wordData, requestId=null) => {
        const command = "upsertWord"
        try {
            const wordId = getValueFromWord(wordData, "wordId")
            const selectedMeaning = getValueFromWord(wordData, "selectedMeaning")
            const userOriginMeaings = extractUserOriginMeanings(wordData)

            const prevItem = await wordDB.items.get({wordId})
            const prevUserOriginMeanings = extractUserOriginMeanings(prevItem ? prevItem.word : null)
            const hasEquivalantUserOriginMeanings = !areEquivalantArrays(prevUserOriginMeanings, userOriginMeaings)

            let word = setValueToWord(wordData, "hasUserOriginMeaning", hasEquivalantUserOriginMeanings)
            word = cleanWordData(word)

            const response = await submit(
                apiEndPoint, 
                {
                    command,
                    parameters: [
                        wordId,
                        JSON.stringify(word),
                        selectedMeaning
                    ]
                },
                requestId
            )

            if (response.status === "success") {
                const updateWord = permutateWordElements(response.items[0])
                const wordId = getValueFromWord(updateWord, "wordId")
                const cId = getValueFromWord(updateWord, "cId")

                await wordDB.items.put(
                    {
                        wordId,
                        cId,
                        word: updateWord,
                        updateTime: getNowInIso8601Jst()
                    }
                )
                console.log(`put wordId: ${wordId} to Indexed DB`)
                return updateWord
            }
            else {
                return {
                    status: "fail",
                    results: response.results
                }
            }
        } catch (error) {
            console.error(`ERROR in ${command}`, error)
            return null
        }
    }

    const getSentenceImage = async (sentenceId, forceUpdate=false) => {

        const command = "getSentenceImage"
        if (!forceUpdate) {
            const item = await imageDB.items.get(sentenceId)
            if (item && getElapsedTime(item.updateTime) < 24 * 3600 * 1000) {
                return item.image
            }
        }

        try {
            const response = await submit(
                apiEndPoint,
                {
                    command,
                    parameters: [sentenceId]
                }
            )

            if (response.status === "success" && response.items.length > 0) {
                const base64data = response.items[0]
                await imageDB.items.put({
                    id: sentenceId,
                    image: base64data,
                    updateTime: getNowInIso8601Jst()
                })
                return base64data
                
            } else {
                return null
            }
        } catch (error) {
            console.error(`ERROR in ${command}`, error)
            return null
        }
    }

    const uploadSentenceImages = async (jsonStreamInfos, requestId=null) => {
        const command = "uploadSentenceImages"
        try {
            const response = await submit(
                apiEndPoint, 
                {
                    command,
                    parameters: [
                        jsonStreamInfos
                    ]
                },
                requestId
            )

            JSON.parse(jsonStreamInfos).forEach(async (streamInfo) => {
                const sentenceId = streamInfo[0]
                const base64data = streamInfo[1]
                await imageDB.items.put({
                    id: sentenceId,
                    image: base64data,
                    updateTime: getNowInIso8601Jst()
                })
            })

            if (response && response?.results && response.results?.totalSentenceImageCount) {
                setTotalSentenceImageCount(response.results.totalSentenceImageCount)
            }

            return response

        } catch (error) {
            console.error(`ERROR in ${command}`, error)
            return null
        }
    }

    const deleteSentenceImages = async (deleteSentenceIds, requestId=null) => {
        const command = "deleteSentenceImages"
        try {
            const response = await submit(
                apiEndPoint, 
                {
                    command,
                    parameters: [
                        JSON.stringify(deleteSentenceIds)
                    ],
                    requestId
                }
            )

            deleteSentenceIds.forEach(async (sentenceId) => {
                await imageDB.items.delete(sentenceId)
            })

            if (response && response?.results && response.results?.totalSentenceImageCount) {
                setTotalSentenceImageCount(response.results.totalSentenceImageCount)
            }

            return response

        } catch (error) {
            console.error(`ERROR in ${command}`, error)
            return null
        }        
    }

    const updateNotes = async (cId, notes) => {
        try {
            const response = await submit(
                apiEndPoint, 
                {
                    command: "updateNotes",
                    parameters: [
                        cId,
                        notes
                    ]
                }
            )

            if (response.status === "success") {
                return true
            }
            else {
                return false
            }
        } catch (error) {
            console.error("ERROR in updateNotes", error)
            return false
        }   
    }

    const proofread = async (displayName, sentenceJa, sentenceEn, requestId=null) => {
        try {
            const response = await submit(
                apiEndPoint, 
                {
                    command: "proofread",
                    parameters: [
                        JSON.stringify({
                            word: displayName,
                            sentenceJa,
                            sentenceEn
                        })
                    ]
                },
                requestId
            )

            if (response.status === "success") {
                return response.items[0]
            }
            else {
                return null
            }
        } catch (error) {
            console.error("ERROR in proofread", error)
            return null
        }        
    }

    const deleteWord = async (wordId, cId, displayName, selectedMeaning, requestId=null) => {
        try {
            const prevItem = await wordDB.items.get({wordId})
            const prevUserOriginMeanings = extractUserOriginMeanings(prevItem ? prevItem.word : null)
            const hasUserOriginMeaning = prevUserOriginMeanings.length > 0

            const response = await submit(
                apiEndPoint, 
                {
                    command: "deleteWord",
                    parameters: [
                        wordId,
                        cId,
                        displayName,
                        selectedMeaning,
                        hasUserOriginMeaning
                    ]
                },
                requestId
            )

            if (response.status === "success") {
                await wordDB.items.delete(wordId)
                return true
            }
            else {
                return false
            }
        } catch (error) {
            console.error("ERROR in deleteWord", error)
            return false
        }   
    }

    const readWord = async (cId, requestId=null) => {
        try {
            const response = await submit(
                apiEndPoint,
                {
                    command: "readWord",
                    parameters: [cId]
                },
                requestId
            )
        
            if (response.status === "success") {
                const word = permutateWordElements(response.items[0])
                const wordId = getValueFromWord(word, "wordId")

                await wordDB.items.put(
                    {
                        wordId,
                        cId,
                        word,
                        updateTime: getNowInIso8601Jst()
                    }
                )
                console.log(`wordId: ${wordId} from backend`)
                return word
            } else {
                return null
            }
        
        } catch (error) {
            console.error("ERROR in readWord", error)
            return null
        }
    }

    const readWordByWordId = async (wordId, query="", forceUpdate=false, requestId=null) => {

        if (!forceUpdate) {
            const item = await wordDB.items.get({wordId})
            if (item && getElapsedTime(item.updateTime) < 24 * 3600 * 1000) {
                return item.word
            }
        }

        try {
            const response = await submit(
                apiEndPoint,
                {
                    command: "readWordByWordId",
                    parameters: [
                        wordId,
                        query
                    ]
                },
                requestId
            )
        
            if (response.status === "success") {
                const word = permutateWordElements(response.items[0])
                const cId = getValueFromWord(word, "cId")

                await wordDB.items.put(
                    {
                        wordId,
                        cId,
                        word,
                        updateTime: getNowInIso8601Jst()
                    }
                )
                console.log(`wordId: ${wordId} from backend`)
                return word
            } else {
                return null
            }
        
        } catch (error) {
            console.error("ERROR in readWordByWordId", error)
            return null
        }
    }

    const updateUserSettings = async (options) => {
        try {
            const response = await submit(
                apiEndPoint,
                {
                    command: "updateUserSettings",
                    parameters: [
                        JSON.stringify(options)
                    ]
                }
            )

            if (response.status === "success") {
                setUserSettings(options)
                return true
            } else {
                return false
            }
        } catch (error) {
            console.error("ERROR in updateUserSettings", error)
            return false
        }
    }

    const getUserWordHistory = async (requestId=null) => {
        try {
            const response = await submit(
                apiEndPoint,
                {
                    command: "getUserWordHistory",
                    parameters: []
                },
                requestId
            )

            if (response.status === "success") {
                const dailyGroupItems = []
                if (response.items) {
                    response.items.forEach((item) => {
                        const i = {
                            dateString: parseDateTimeStringToJaDate(item.updatedAt),
                            dayColor: parseDateTimeStringToDayColor(item.updatedAt),
                            timeString: parseDateTimeStringToJaTime(item.updatedAt),
                            activity: item.activity,
                            cId: item?.cId,
                            wordId: item?.wordId,
                            displayName: item?.displayName,
                            selectedMeaning: item?.selectedMeaning,
                            answerStatus: item?.answerStatus,
                        }
                        
                        if (dailyGroupItems.length > 0 && dailyGroupItems[dailyGroupItems.length - 1][0].dateString === i.dateString) {
                            dailyGroupItems[dailyGroupItems.length - 1].push(i)
                        } else {
                            dailyGroupItems.push([i])
                        }
                    })
                    return dailyGroupItems
                }
                return null
            } else {
                return null
            }
        } catch (error) {
            console.error("ERROR in getUserWordHistory", error)
            return null
        }
    }


    const speechToText = async (referenceText, base64Audio, requestId=null) => {
        try {
            const response = await submit(
                apiEndPoint,
                {
                    command: "speechToText",
                    parameters: [
                        referenceText,
                        base64Audio
                    ]
                },
                requestId
            )

            if (response.status === "success") {
                const nBest = response.items[0].NBest[0]
                return nBest
            } else {
                return null
            }
        } catch (error) {
            console.error("ERROR in speechToText", error)
            return null
        }
    }

    const getWordList = async (sortOrder, startIndex, requestId=null) => {
        const command = "getWordList"
        try {
            const response = await submit(
                apiEndPoint,
                {
                    command,
                    parameters: [
                        sortOrder,
                        startIndex
                    ]
                },
                requestId
            )

            if (response.status === "success") {

                return response.items
            }
            else {
                console.log(`response: ${JSON.stringify(response)}`)
                return null
            }
        } catch (error) {
            console.error(`ERROR in ${command}`, error)
            return null
        }
    }

    const setStar = async (cId, value, requestId=null) => {
        const command = "setStar"
        try {
            const response = await submit(
                apiEndPoint,
                {
                    command,
                    parameters: [
                        cId,
                        value
                    ]
                },
                requestId
            )

            if (response.status === "success") {
                return response.items
            }
            else {
                console.log(`response: ${JSON.stringify(response)}`)
                return null
            }
        } catch (error) {
            console.error(`ERROR in ${command}`, error)
            return null
        }
    }

    const getDailyQuizHistory = async (addDays, requestId=null) => {
        const command = "getDailyQuizHistory"
        const endIso8601JstDateTime = getTodayInIso8601Jst()
        const dt = new Date()
        dt.setDate(dt.getDate() + addDays)
        const startIso8601JstDateTime = convertDateInIso8601Jst(dt)

        try {
            const response = await submit(
                apiEndPoint,
                {
                    command,
                    parameters: [
                        startIso8601JstDateTime,
                        endIso8601JstDateTime
                    ]
                },
                requestId
            )

            if (response.status === "success") {
                return response.items
            }
            else {
                // console.log(`response: ${JSON.stringify(response)}`)
                return null
            }
        } catch (error) {
            console.error(`ERROR in ${command}`, error)
            return null
        }        
    }

    const deleteAccount = async (requestId=null) => {
        const command = "deleteAccount"
        try {
            const response = await submit(
                apiEndPoint,
                {
                    command,
                    parameters: []
                },
                requestId
            )

            if (response.status === "success") {
                return true
            }
        } catch (error) {
            console.error(`ERROR in ${command}`, error)
        }

        return false        
    }    

    return {
        registerUser,
        retrieveUser,
        setAvatarImage,
        getAvatarImage,
        getWordStatus,
        getQuizWords,
        getQuizWordsRandomly,
        searchWord,
        commitActivity,
        upsertWord,
        getSentenceImage,
        uploadSentenceImages,
        deleteSentenceImages,
        updateNotes,
        proofread,
        deleteWord,
        readWord,
        readWordByWordId,
        updateUserSettings,
        getUserWordHistory,
        speechToText,
        getWordList,
        setStar,
        getDailyQuizHistory,
        deleteAccount,
        isLoading
    }
}

export default useApi
