import React, { useState, useEffect } from "react";
import {
    Box,
    HStack,
    Stack,
    Button,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    useDisclosure
} from "@chakra-ui/react";
import {
    CloseIcon
} from '@chakra-ui/icons'
import { ModalFooter } from "react-bootstrap";

import "../App.css";
import useApi from "../hooks/useApi"
import ImageElement from "./ImageElement";
import { removeLinkTags } from "../CommonFunction"

const WordSnippetDialog = (props) => {
    const {
        readWordByWordId,
        setStar,
        isLoading
    } = useApi() 

    const { 
        isOpen: isSnippetOpen,
        onOpen: onSnippetOpen,
        onClose: onSnippetClose
    } = useDisclosure()    

    const [requestId, setRequestId] = useState(null)

    useEffect(() => {
        if (props.selectedWord.index >= 0) {
            onSnippetOpen()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedWord])

    const openDisplayWord = async () => {
        props.onSpinnerOpen()
        const wordData = await readWordByWordId(props.wordId)

        if (wordData) {
            props.onSpinnerClose()

            let previous = {
                scrollPosition: props.getScrollPosition ? props.getScrollPosition() : 0
            }
            if (props?.viewSettings) {
                previous = {
                    ...previous,
                    hander: "WordList",
                    viewSettings: props.viewSettings,
                    dataRepo: props.dataRepo,
                }
            }

            props.dispatch({
                type: "selectWord",
                data: {
                    previous,
                    current: {
                        wordData
                    }
                }
            })
        }
    }

    return (
        <Modal 
            isOpen={isSnippetOpen} 
            onClose={() => {
                props.setSelectedWord({
                    ...props.selectedWord,
                    index: -1
                })
                onSnippetClose()
            }}
            isCentered={true}
        >
            <ModalOverlay />
            <ModalContent
                maxW="340px"
            >
                <ModalHeader>
                    <HStack>
                        <Box
                            width="20px"
                            m="0px 0px 0px 0px"
                        >
                            {
                                requestId && isLoading(requestId) ?
                                    <Spinner
                                        thickness='4px'
                                        speed='0.65s'
                                        emptyColor='gray.200'
                                        color='blue.500'
                                        size='sm'
                                        m="4px 0px 0px 0px"
                                    />           
                                    :
                                    <Box
                                        onClick={async ()=> {
                                            const requestId = crypto.randomUUID()
                                            const value = !props.isStarred
                                            setRequestId(requestId)
                                            const response = await setStar(props.cId, value, requestId)
                                            if (response) {
                                                setRequestId(null)
                                                props.setStar(value)
                                            }
                                        }}
                                        color={props.isStarred ? "yellow.300" : "gray.300"}
                                        side="3xl"
                                    >
                                        ★
                                    </Box>
                            }
                        </Box>
                        <Box>
                            {props.displayName}
                        </Box>
                    </HStack>
                </ModalHeader>
                <ModalCloseButton>
                    <CloseIcon
                        onClick={()=>{
                            props.setSelectedWord({
                                ...props.selectedWord,
                                index: -1
                            })
                            onSnippetClose()
                        }}
                    />
                </ModalCloseButton>
                <ModalBody>
                    <Stack
                        justifyContent="center"
                        alignItems="center"
                        width="100%"             
                    >
                        <HStack
                            pb="10px"
                            width="100%"
                        >
                            <Box
                                bgColor="blue.100"
                                fontSize="xs"
                            >
                                意味
                            </Box>
                            <Box
                                flex="1"
                            >
                                {removeLinkTags(props.meaning)}
                            </Box>
                        </HStack>
                        <ImageElement
                            url={props.wordImageUrl}
                            maxW="330px"
                            minW="330px"
                        />
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    {
                        props?.switchToFlashCardMode ?
                            <Button
                                colorScheme="gray"
                                onClick={()=> {
                                    props.switchToFlashCardMode()
                                    onSnippetClose()
                                }}
                                m="0px 0px 8px 0px"
                            >
                                単語帳モードを開始
                            </Button>
                            :
                            <></>
                    }
                    <Button
                        onClick={async ()=>{
                            await openDisplayWord()
                        }}
                        colorScheme="blue"
                        width="100px"
                        m="0px 8px 8px 8px"
                    >
                        詳細
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default WordSnippetDialog