import React, { useState, useRef, useEffect } from "react"
import { 
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal
} from '@azure/msal-react'
import { 
    Avatar,
    Button,
    Box,
    Tooltip,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    HStack,
    Stack,
    Divider,
    Spacer,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    SliderMark,
    Switch
} from '@chakra-ui/react'
import { loginRequest } from '../authConfig'
import { useCookies } from "react-cookie";


import AvatarImageDialog from "./AvatarImageDialog"
import {useUserContext} from "../context/userContext"
import useApi from "../hooks/useApi";

const AccountDialog = () => {

    const [, setCookie] = useCookies();    
    /**
     * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
     * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
     * only render their children if a user is authenticated or unauthenticated, respectively.
     */

    const { instance, accounts } = useMsal();
    const isAuthenticated = accounts.length > 0;

    const {
        setAvatarImage,
        getAvatarImage,
        updateUserSettings
    } = useApi()

    const {
        getUserId,
        getUserName,
        getUserEmail,
        clearUserInfo,
        getQuizMode,
        getMaxQuizCount,
        getFsrsRetention
    } = useUserContext()

    const handleLoginRedirect = async () => {
        try {
            await instance.loginRedirect({
                ...loginRequest,
                prompt: "select_account"
            })
            .catch((error) => console.log(error))
            setCookie("loginPrompt", "signIn")

        }
        catch(error) {
            console.log(error)
        }
    }

    const handleLogoutRedirect = async () => {
        try {
            await clearUserInfo()
            await instance.logoutRedirect()
        } catch (error) {
            console.log(error)
        }
    }

    //////////////////////////////////////////////////////////////////////////
    // settings

    // quiz format
    const [tempQuizMode, setTempQuizMode] = useState(getQuizMode())
    const tempQuizModeRef = useRef(getQuizMode())

    // max quiz count
    const [tempMaxQuizCount, setTempMaxQuizCount] = useState(getMaxQuizCount())
    const tempMaxQuizCountRef = useRef(getMaxQuizCount())

    // fsrs retention
    const [tempFsrsRetention, setTempFsrsRetention] = useState(getFsrsRetention())
    const tempFsrsRetentionRef = useRef(getFsrsRetention())
    //////////////////////////////////////////////////////////////////////////
    // avatar image

    const [imageData, setImageData] = useState(null)
    const [prevImageData, setPrevImageData] = new useState(null)
    const [isImageUpdated, setIsImageUpdated] = useState(false)

    useEffect(()=> {
        (async ()=> {
            if (isAuthenticated) {
                const image = await getAvatarImage()
                if (image && !isImageUpdated) { // Only set if image isn't updated
                    setImageData(image);
                }            }
            }
        )()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accounts, isAuthenticated, getAvatarImage]);

    const closeButtonRef = useRef(null)

    return (
        <>
            <AuthenticatedTemplate>
                <Box 
                    className="collapse navbar-collapse justify-content-end"
                    m="0px 8px 0px 0px"
                >
                    <Popover
                        placement='bottom'
                        closeOnBlur={false}
                        trigger="click"
                        onOpen={()=> {
                            setTempQuizMode(getQuizMode())
                            tempQuizModeRef.current = getQuizMode()
                            setTempMaxQuizCount(getMaxQuizCount())
                            tempMaxQuizCountRef.current = getMaxQuizCount()
                            setTempFsrsRetention(getFsrsRetention())
                            tempFsrsRetentionRef.current = getFsrsRetention()
                        }}
                    >
                        <PopoverTrigger>
                            <Avatar
                                name={getUserName()}
                                w={50}
                                h={50}
                                sx={{img:{borderRadius:25}}}
                                src={imageData}
                            />
                        </PopoverTrigger>

                        <PopoverContent
                            color='white'
                            bg='blue.700'
                            borderColor='blue.700'
                            width="360px"
                        >
                            <PopoverHeader 
                                pt={4}
                                fontWeight='bold'
                                border='0'
                            >
                                アカウント情報
                            </PopoverHeader>
                            <PopoverArrow
                                bg='blue.700' 
                            />
                            <PopoverCloseButton
                                ref={closeButtonRef}
                            />
                            <PopoverBody>
                                <HStack>
                                    <Stack>
                                        <Box>
                                            <Avatar
                                                name={getUserName()}
                                                w={50}
                                                h={50}
                                                sx={{img:{borderRadius:25}}}
                                                src={imageData}
                                            />
                                        </Box>
                                        <Box 
                                            mx={4}
                                            height={8}
                                        >
                                            {
                                                getUserId() ?
                                                    <AvatarImageDialog 
                                                        imageData={imageData}
                                                        setImageData={setImageData}
                                                        prevImageData={prevImageData}
                                                        setPrevImageData={setPrevImageData}
                                                        setIsImageUpdated={setIsImageUpdated}
                                                    />
                                                    :
                                                    <></>
                                            }
                                        </Box>
                                    </Stack>
                                    <Stack
                                        align="start"
                                        justify="start"
                                        height="93px"
                                    >
                                        <Box
                                            m="0px"
                                        >
                                            {getUserName()}
                                        </Box>
                                        <Box>
                                            {getUserEmail()}
                                        </Box>
                                    </Stack>
                                </HStack>
                                <Divider
                                    borderColor="gray.100"
                                />
                                <Stack
                                    m="0px 0px 16px 0px"
                                >
                                    <Box><strong>設定</strong></Box>
                                </Stack>
                                <Stack
                                    m="0px 0px 16px 0px"
                                >
                                    <Box>
                                        クイズ出題形式
                                    </Box>
                                    <Box
                                        m="0px 24px 8px 0px"
                                    >
                                        <HStack
                                            ml="20px"
                                        >
                                            <Box>選択式</Box>
                                            <Switch 
                                                size="md"
                                                colorScheme="blue"
                                                onChange={(e)=> {
                                                    setTempQuizMode(tempQuizMode === "normal" ? "advanced" : "normal")
                                                }}
                                                isChecked={tempQuizMode === "advanced"}
                                                sx={{
                                                    '& .chakra-switch__track': 
                                                        tempQuizMode === "advanced" ?
                                                            {backgroundColor:"red.500"} : {backgroundColor:"blue.500"}
                                                }}
                                            />
                                            <Box>記述式 (※上級編)</Box>
                                        </HStack>
                                    </Box>
                                </Stack>                                
                                <Stack
                                    m="0px 0px 16px 0px"
                                >
                                    <Box>
                                        クイズ最大出題数: {tempMaxQuizCount}
                                    </Box>
                                    <Box
                                        m="0px 24px 8px 0px"
                                    >
                                        <Slider
                                            defaultValue={tempMaxQuizCount}
                                            min={10}
                                            max={100}
                                            onChange={(value) => 
                                                setTempMaxQuizCount(value)
                                            }
                                        >
                                            <SliderMark value={10}>
                                                10
                                            </SliderMark>
                                            <SliderMark value={50}>
                                                50
                                            </SliderMark>
                                            <SliderMark value={100}>
                                                100
                                            </SliderMark>
                                            <SliderTrack>
                                                <SliderFilledTrack />
                                            </SliderTrack>
                                            <SliderThumb />
                                        </Slider>
                                    </Box>
                                </Stack>
                                <Stack
                                    m="0px 0px 16px 0px"
                                >
                                    <Box>
                                        クイズ目標正解率: {tempFsrsRetention * 100} %
                                    </Box>
                                    <Box
                                        m="0px 24px 8px 0px"
                                    >
                                        <Slider
                                            defaultValue={tempFsrsRetention}
                                            min={0.85}
                                            max={0.95}
                                            step={0.01}
                                            onChange={(value) => 
                                                setTempFsrsRetention(value)
                                            }
                                        >
                                            <SliderMark value={0.85}>
                                                85%
                                            </SliderMark>
                                            <SliderMark value={0.9}>
                                                90%
                                            </SliderMark>
                                            <SliderMark value={0.95}>
                                                95%
                                            </SliderMark>
                                            <SliderTrack>
                                                <SliderFilledTrack />
                                            </SliderTrack>
                                            <SliderThumb />
                                        </Slider>
                                    </Box>
                                </Stack>

                            </PopoverBody>
                            <PopoverFooter
                                border='0'
                                display='flex'
                                alignItems='center'
                                justifyContent='space-between'
                                pb={4}
                            >
                                <Button
                                    colorScheme="blue"
                                    onClick={async () => {
                                        const options = {}
                                        if (tempQuizMode !== tempQuizModeRef.current) {
                                            options.quizMode = tempQuizMode
                                        }
                                        if (tempMaxQuizCount !== tempMaxQuizCountRef.current) {
                                            options.maxQuizCount = tempMaxQuizCount;
                                        }
                                        if (tempFsrsRetention !== tempFsrsRetentionRef.current) {
                                            options.fsrsRetention = tempFsrsRetention
                                        }

                                        if (Object.keys(options).length > 0) {
                                            await updateUserSettings(options)
                                        }

                                        if (isImageUpdated) {
                                            await setAvatarImage(imageData)
                                        }

                                        closeButtonRef.current.click()
                                    }}
                                    isDisabled={
                                        tempQuizMode === tempQuizModeRef.current
                                        && tempMaxQuizCount === tempMaxQuizCountRef.current
                                        && tempFsrsRetention === tempFsrsRetentionRef.current
                                        && !isImageUpdated
                                        
                                    }
                                    mr="6px"
                                >
                                    更新
                                </Button>
                                <Button
                                    colorScheme="gray"
                                    onClick={async () => {
                                        if (prevImageData) {
                                            setImageData(prevImageData)
                                        }
                                        setPrevImageData(null)
                                        setIsImageUpdated(false)
                                        closeButtonRef.current.click()
                                    }}
                                >
                                    キャンセル
                                </Button>
                                <Spacer/>
                                <Button
                                    colorScheme="red"
                                    onClick={handleLogoutRedirect}
                                >
                                    サインアウト
                                </Button>
                            </PopoverFooter>
                        </PopoverContent>
                    </Popover>
                </Box>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Box className="collapse navbar-collapse justify-content-end">
                    <Tooltip label="クリックしてサインイン" hasArrow bg="gray.300" color="black">
                        <Avatar 
                            w={50}
                            h={50}
                            sx={{img:{borderRadius:25}}}
                            onClick={handleLoginRedirect}
                        />
                    </Tooltip>
                </Box>
            </UnauthenticatedTemplate>
        </>
    )
}

export default AccountDialog
