import React from 'react'
import {
    Box,
    Button,
    Stack,
    Text,
    useDisclosure
} from "@chakra-ui/react";
import {useUserContext} from "../../context/userContext"
import useApi from '../../hooks/useApi'
import BackButton from '../BackButton';
import FullCoverSpinner from "../FullCoverSpinner";
import HeaderComponent from '../HeaderComponent';

const NoWordToQuiz = (props) => {
    const { 
        getQuizWordsRandomly
    } = useApi()

    const { 
        isOpen: isSpinnerOpen,
        onOpen: onSpinnerOpen,
        onClose: onSpinnerClose
    } = useDisclosure()      

    const onClick = async () => {
        onSpinnerOpen()
        const quizWords = await getQuizWordsRandomly()

        if (quizWords) {
            onSpinnerClose()
            props.dispatch({
                type: "quizInit",
                data: {
                    previous: {},
                    current: {
                        ...props.data,
                        isRandom: true,
                        quizWords
                    }
                }
            })
        }
    }

    const {
        getTotalWordCount
    } = useUserContext()

    return (
        <Stack
            margin="0%"
            textAlign="left"
            p="1"
            rounded="md"
            width="100%"        
        >
            <FullCoverSpinner
                isOpen={isSpinnerOpen} 
                onClose={onSpinnerOpen}
            />

            <HeaderComponent
                setLaunchSubApp={props.setLaunchSubApp}
                content={
                    <Text
                        fontSize="xl"
                        m="0px 0px 0px 12px"
                    >
                        課題となるクイズがありません
                    </Text>
                }
            />            
            {
                getTotalWordCount() > 0 ?
                    <>
                        <Box
                            m="10px 0px 10px 0px"
                        >
                            現在全ての課題を完了しています。<br/>
                            ランダム抽出でクイズを行いますか？
                        </Box>
                        <Button
                            onClick={() => onClick()}
                            colorScheme="blue"
                            width="100px"                        
                        >
                            クイズ開始
                        </Button>
                    </>
                    :
                    <Box>
                        単語が登録されていないため、クイズを出題できません
                    </Box>
            }

            <Box
                width="100%"
                m="20px 0px 20px 40px"
            >
                <BackButton
                    dispatch={props.dispatch}
                />
            </Box>        

        </Stack>
    )
}

export default NoWordToQuiz;