import React, { useEffect } from "react";
import {
    Box,
    Button,
    Stack,
    VStack,
    Center,
    Text,
    Heading,
    Image,
    Card,
    CardBody,
    Container,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure
} from '@chakra-ui/react'
import {
    CloseIcon
} from '@chakra-ui/icons'
import { 
    useMsal,
    useIsAuthenticated
} from '@azure/msal-react';
import { loginRequest } from "../authConfig"
import { useCookies } from "react-cookie";
import LicenseAgreementDialog from "./LicenseAgreementDialog";
import LoginRequestDialog from "./LoginRequestDialog"
import HeaderComponent from "./HeaderComponent"
import ContentCard from "./ContentCard";
import { getUserAgentInfo } from "../CommonFunction"

// image resources
import bg001 from "../assets/photos/bg001.jpg"
import eikatsuLogo from "../assets/icons/eikatsu-logo.png"
import eikatsuTop from "../assets/screenshots/eikatsu-top.png"
import forgettingCurve1 from "../assets/diagrams/forgettingCurve1.jpg"
import forgettingCurve2 from "../assets/diagrams/forgettingCurve2.jpg"
import quizWord_short from "../assets/screenshots/quizWord_short.jpg"
import displayWord2_short from "../assets/screenshots/displayWord2_short.jpg"
import displayWord from "../assets/screenshots/displayWord.png"
import studyWord from "../assets/screenshots/studyWord.png"
import studyWord_step1 from "../assets/screenshots/studyWord_step1.jpg"
import studyWord_step2 from "../assets/screenshots/studyWord_step2.jpg"
import studyWord_step3 from "../assets/screenshots/studyWord_step3.jpg"
import studyWord_step4 from "../assets/screenshots/studyWord_step4.jpg"
import signIn_short from "../assets/screenshots/signIn_short.png"

const SingOutMainPage = (props) => {

    const [, setCookie, removeCookie] = useCookies();

    const { 
        isOpen: isErrorDialogOpen,
        onOpen: onErrorDialogOpen,
        onClose: onErrorDialogClose
    } = useDisclosure()        

    const { 
        isOpen: isLicenseAgreementDialogOpen,
        onOpen: onLicenseAgreementDialogOpen,
        onClose: onLicenseAgreementDialogClose
    } = useDisclosure()
    
    const { 
        isOpen: isLoginRequestDialogOpen,
        onOpen: onLoginRequestDialogOpen,
        onClose: onLoginRequestDialogClose
    } = useDisclosure()

    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    // console.log(`Local URL: ${process.env.REACT_APP_LOGIN_REQUEST_URL_LOCAL}`);
    // console.log(`Production URL: ${process.env.REACT_APP_LOGIN_REQUEST_URL}`);
    // console.log(`loginRequest:${JSON.stringify(loginRequest)}`)
    // console.log(`getUserAgentInfo:${JSON.stringify(getUserAgentInfo())}`)

    const handleRedirect = async (prompt) => {
        setCookie("loginPrompt", prompt === "create" ? "signUp" : "signIn")

        if (getUserAgentInfo().os === "ios") {
            onLoginRequestDialogOpen()
        } else {
            await instance.loginRedirect({
                ...loginRequest,
                prompt: prompt
            }).catch((error) => console.log(error))
        }
    };

    const handleClickClose = async () => {
        removeCookie("loginPrompt")
        onErrorDialogClose()
        await instance.logoutRedirect()        
    }

    useEffect(() => {
        if (props.errorMessages.length > 0) {
            onErrorDialogOpen()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instance, isAuthenticated, props.errorMessages])    
        
    return (
        <Container
            p="0px"
            width="100%"
            flex="1"
        >
            <LicenseAgreementDialog
                isOpen={isLicenseAgreementDialogOpen}
                onOpen={onLicenseAgreementDialogOpen}
                onClose={onLicenseAgreementDialogClose}

                onLoginRequestDialogOpen={onLoginRequestDialogOpen}
            />

            <LoginRequestDialog
                isLoginRequestDialogOpen={isLoginRequestDialogOpen}
                onLoginRequestDialogClose={onLoginRequestDialogClose}
            />

            <Modal 
                isOpen={isErrorDialogOpen} 
                onClose={() => handleClickClose()}
                isCentered={true}
            >
                <ModalOverlay />
                <ModalContent
                    maxW="340px"
                >
                    <ModalHeader>
                            {props.errorMessages[0]}
                    </ModalHeader>
                    <ModalCloseButton>
                        <CloseIcon
                            onClick={()=>handleClickClose()}
                        />
                    </ModalCloseButton>
                    <ModalBody>
                        {
                            props.errorMessages.slice(1).map((errorMessage) => 
                                <Text>{errorMessage}</Text>
                            )
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={()=>handleClickClose()}
                        >
                            閉じる
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

          
            <Stack
                margin="0%"
                textAlign="left"
                p="1"
                rounded="md"
                width="100%"
                position="relative"        
            >
                <HeaderComponent
                    setLaunchSubApp={props.setLaunchSubApp}
                    content={
                        <VStack
                            position="absolute"
                            top="0"
                            left="0"
                            width="100%"
                            m="0px 0px 0px 0px"
                        >
                            <Box
                                color="blue.500"
                            >
                                アカウントをお持ちの方はこちら
                            </Box>
                            <Button 
                                onClick={() => handleRedirect("select_account")}
                                colorScheme="blue"
                            >
                                サインイン
                            </Button>

                        </VStack>
                    }
                />

                <Stack
                    textAlign="left"
                    rounded="md"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"                
                >
                    <Card
                        direction={{ base: 'column', sm: 'row' }}
                        overflow='hidden'
                        variant='outline'
                        onClick={() => onLicenseAgreementDialogOpen()}
                        w={[350, 450, 650]}
                        boxShadow="md"
                        m="40px 0px 8px 0px"
                        height="100%"
                        borderColor="blue.300"
                        borderWidth="2px"
                    >
                        <CardBody
                            width="100%"
                            p="5px"
                            zIndex={1}
                            position="relative"
                            _before={{
                                content: `""`,
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), url(${bg001})`,
                                backgroundPosition: "top",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                zIndex: -1
                            }}
                        >
                            <Box
                                height="120px"
                                width="100%"
                                position="relative"
                        >
                                <Heading
                                    position="absolute"
                                    width="240px"
                                    left="50px"
                                    top="0px"
                                    fontSize="22px"
                                    color="white"
                                    style={{textShadow: `2px 2px 0 #2b6cb0, -1px -1px 0 #2b6cb0, -1px 1px 0 #2b6cb0, 1px -1px 0 #2b6cb0`}}
                                    // style={{textShadow: `1px 1px 0 #90cdf4, -1px -1px 0 #2b6cb0, -1px 1px 0 #3182ce, 1px -1px 0 #3182ce`}}
                                    // style={{textShadow: `1px 1px 0 #3182ce, -1px -1px 0 #2b6cb0, -1px 1px 0 #90cdf4, 1px -1px 0 #3182ce`}}
                                >
                                    もう一歩先の英語力へ
                                </Heading>
                                <Box
                                    position="absolute"
                                    width="360px"
                                    left="118px"
                                    top="48px"
                                    fontSize="14px"
                                    color="blue.600"
                                >
                                    英活はあなたの英語力向上を
                                </Box>
                                <Box
                                    position="absolute"
                                    width="360px"
                                    left="118px"
                                    top="68px"
                                    fontSize="14px"
                                    color="blue.600"
                                >
                                    効率よくお手伝いします
                                </Box>
                            </Box>
                            <Center>
                                <Button 
                                    onClick={()=>onLicenseAgreementDialogOpen()}
                                    // colorScheme="red"
                                    m="0px 0px 60px 0px"
                                    bg="transparent"
                                    border="2px solid #3182ce"
                                    color="blue.500"
                                    _hover={{ bg: "blue.500", color: "white" }}

                                >
                                    アカウント作成
                                </Button>
                            </Center>                            
                            <Center
                                m="0px 0px 0px 0px"
                            >
                                <Text
                                    fontSize="2xs"
                                    color="blue.600"
                                    m="0% 3% 0% 3%"
                                    p="0px 2px 0px 2px"
                                    bg="rgba(255, 255, 255, 0.5)"
                                >
                                   現在アカウントを作成頂いた先着１００名様限定で、３０日間トライアルのキャンペーンを実施中です。期間中は全ての機能が無料でお使いいただけます。
                                </Text>
                            </Center>

                        </CardBody>
                    </Card>

                    <Heading
                        size="lg"
                        m="20px 0px 20px 0px"
                        as="u"
                    >
                        英活の特徴
                    </Heading>

                    <ContentCard
                        title="オールインワンアプリ"
                        cardImage={
                            <Image
                                src={eikatsuLogo} 
                                alt="英活ロゴ"
                                width="60px"
                            />                    
                        }
                        summary=
                            "英活は英単語学習に必須の「調べる」「学習する」「記憶を確認する」「反復記憶する」のアクションを効率よくサポートする統合型の英単語学習用アプリです。"
                        
                        details={
                            <>
                                <Text>英語上級者にとって、自身の専門分野や興味分野等に特化した自分用にカスタマイズされた英単語集の作成・管理は大変に重要です。お仕着せの単語集や万人向けの学習教材だけで学習を進めることは、英語レベルの向上に伴い徐々に効率の悪い学習方法となってきます。</Text>
                                <Text>一方で自分専用の英単語集を維持・管理し、継続的に学習するのは相当の時間とコストを伴います。また増え続けていく未修得単語を適切なタイミングで反復学習し、記憶語彙を維持・増加していくことは容易なことではありません。</Text>
                                <Text>この問題を解決するために、英活アプリは、辞書検索、自分用にカスタマイズされた単語集の作成・管理、AIを使った単語学習、個々の単語の復習タイミング自動調整、等の英単語の記憶強化に必要な機能を一つに統合しました。英活はあなた専用の英単語集による学習を「調べる」「学習する」「記憶を確認する」「反復記憶する」の全アクションを効率的にサポートします。</Text>
                                <Center>
                                    <Image
                                        src={eikatsuTop}
                                        border="1px solid #90cdf4"
                                        width="320px"
                                        alt="英活・トップページ"
                                    />
                                </Center>                
                            </>
                        }
                    />

                    <ContentCard
                        title="長期記憶定着の促進"
                        cardImage={
                            <Image
                                src={forgettingCurve1} 
                                width="80px"
                                alt="忘却曲線"
                            />                    
                        }
                        summary={
                            "英活はあなたが登録した英単語を脳の長期記憶領域に留めるために、最適な復習タイミングを自動的にスケジュールするアルゴリズムを採用しています。"
                        }
                        details={
                            <>
                                <Text>英単語学習で最も悩ましい事は、どんなに真剣に学習に取り組んでいても直ぐに忘れてしまうことです。これはあなたの記憶力の問題ではなく、人間の脳の記憶メカニズムよるものであることは科学的にも証明されています。</Text>
                                <Center>
                                    <Image
                                        src={forgettingCurve1}
                                        width="320px"
                                        alt="忘却曲線"
                                    />
                                </Center> 
                                <Text>上のグラフは「忘却曲線」と呼ばれる「記憶の定着率」(Y軸) と「記憶してから経った日数」(X軸) の関係を表したものです。①の赤い曲線は「何かを記憶しても１日後には半分以上は忘れてしまう」事を意味しています。「６日後には更に忘れてしまう」ことを意味しています。人間の脳は実に忘れやすく出来ているのです。</Text>
                                <Text>その右横の②の緑の曲線は「同じ内容を１日後に再度記憶しなおした場合の記憶の定着率」を表しています。①と比べると忘れ方が緩やかになっている、つまり２回目の記憶により記憶が定着しやすくなっている、ということを表しています。更に③の曲線は２日後の再記憶の結果、④は３日後・・・と反復するにつれて徐々に忘れにくくなっていることがわかります。</Text>
                                <Text>このように人間の脳は、記憶行為を反復することで記憶をより定着させ、長期記憶領域に記憶を形成することがわかっています。<br/>つまり英単語を覚えたければ毎日毎日繰り返し学習すればよいのです。</Text>
                                <Center>
                                    <Image
                                        src={forgettingCurve2}
                                        width="320px"
                                        alt="忘却曲線その２"
                                    />
                                </Center>
                                <Text>但しこの繰り返し学習には、学習効率を高めるために考慮すべき重要な点があります。<br/>それは個々の単語には覚えやすい単語と覚えにくい単語がある、ということです。更に詳しく言うと、同じ単語でも覚えやすさには個人差があります。</Text>
                                <Text>人間の脳は既に脳内に形成されている記憶（＝個人がこれまでの人生で思考や経験等によって培ってきた情報）と関連付けることで新しい記憶を定着させます。新しい英単語を記憶するときに、簡単に記憶できるモノと、修得にとても時間が掛かるモノがあるのは、関連付けできる情報が既に脳内にあるかないか、そして既存の記憶と新しい記憶の関連強弱に依存しています。</Text>
                                <Text>この事実を考慮せずに、単純に全量の反復記憶を行うことは、記憶したい単語数が増えれば増えるほど深刻なロスに繋がります。冗長な記憶作業を繰り返すことは、学習進捗をスローダウンさせ、延いては学習意欲を失う原因になり兼ねません。例えば１０００の新しい英単語を覚えなければいけない状況を仮定した時、毎日毎日１０００単語を愚直に繰り返し学習することが本当に効率のよい方法でしょうか？既に覚えている単語の反復はスキップして、その分まだ覚えていない単語記憶により多くの時間を使うほうが理にかなった方法ではないでしょうか？</Text>
                                <Text>単語の記憶定着度によって反復頻度を変えるのは良い方法ですが、新たな課題「各単語の記憶定着度をどうやって測定するか？」に直面します。確実に記憶している単語、逆に全く覚えていない単語、の両極端なケースを取捨選択するのは比較的簡単ですが、その中間にある大量の「覚えているけどまだ自信がない」単語をどう判定し、いつ次の反復記憶を行えばよいのでしょうか？</Text>
                                <Text>この問題を解決するために、英活では間隔反復法 (Spaced Repetition Scheduler) と呼ばれるアルゴリズムを採用し、各単語の復習タイミングを適切に調整します。<br/>具体的には英活のクイズ機能を通して、各回答毎に単語の理解度を報告していただくことで、単語の理解度、その単語の過去のクイズ出題回数および時期、過去出題時の理解度、などの要素を考慮し、次の復習タイミングを適切に計算します。<br/>未だ修得度の低いと想定される単語の復習タイミングは短めにスケジュール、既に十分に修得している単語の復習タイミングは長めにスケジュールすることで、学習セット全体での記憶定着率を向上させつつ、効率よく時間配分も行うバランスを考慮した仕組みを採用しています。</Text>
                                <Center>
                                    <Image
                                        src={quizWord_short}
                                        width="320px"
                                        alt="英活・クイズ理解度入力"
                                    />
                                </Center>                                
                            </>
                        }
                    />

                    <ContentCard
                        title="組み込み英和辞書と豊富な情報"
                        cardImage={
                            <Image
                                src={displayWord2_short} 
                                alt="英活・単語表示画面"
                                width="80px"
                            />                    
                        }
                        summary={
                            "英活は約５万語の語彙をカバーする英和辞書を実装しています。各単語の意味だけでなく音声や例文など豊富な情報にアクセスできます。"
                        }
                        details={
                            <>
                                <Text>
                                    英活には約５万語の語彙をカバーする英和辞書が組み込まれています。
                                </Text>
                                <Text>
                                    この語彙集に日本語・英語に対応した検索機能でアクセスできます。英語のスペルミスや日本語の表記ゆれ（例「猫」「ねこ」「ネコ」）にも対応した柔軟な検索により目的の単語に簡単にアクセスできます。
                                </Text>
                                <Text>
                                    各単語の詳細情報ページには、意味（複数）、発音記号、品詞、発音音声、複数の例文など、単語の記憶しやすくするエピソード等が提供されており、単語をより記憶しやすくするための多くの情報が提供されています。
                                </Text>
                                <Center>
                                    <Image
                                        width="320px"
                                        src={displayWord}
                                        border="1px solid #90cdf4"
                                    />
                                </Center> 
                            </>
                        }
                    />

                    <ContentCard
                        title="様々な単語学習機能"
                        cardImage={
                            <Image
                                src={studyWord} 
                                alt="英活・学習画面"
                                width="80px"
                            />                    
                        }
                        summary={
                            "英活は登録した英単語を学習する機能を備えています。実際に単語を使って様々な方法で学習することで、単語の記憶定着を促進します。"
                        }
                        details={
                            <>
                                <Text>
                                    英活では自分が登録した全ての英単語を学習することができます。最大４つのステップを、実際にその英単語を使って集中的に学習することで、効率のよい英単語記憶を促進します。
                                </Text>
                                <Heading size="sm">Step 1: 単語の発音と発声</Heading>
                                <Text>
                                    女性と男性の声による単語発音オーディオが再生ができます。またその発音にならって自分の発音を録音し聴くことができます。お手本の発音と比較するなどしてご自身の発音の改善に役立てることができます。
                                </Text>
                                <Center>
                                    <Image
                                        src={studyWord_step1}
                                        border="1px solid #90cdf4"
                                        width="320px"
                                        alt="英活・学習画面:step1"
                                    />
                                </Center>
                                <Heading size="sm">Step 2: 単語書き取り</Heading>
                                <Text>
                                    単語を綴り通り正確にタイプすることでスペルを記憶します。
                                </Text>
                                <Center>
                                    <Image
                                        src={studyWord_step2}
                                        border="1px solid #90cdf4"
                                        width="320px"
                                        alt="英活・学習画面:step2"
                                    />
                                </Center>
                                <Heading size="sm">Step 3: 例文の発音と発声</Heading>
                                <Text>
                                    例文の発音オーディオを再生し、それに倣って自分の発音を録音し聴くことができます。
                                </Text>
                                <Center>
                                    <Image
                                        src={studyWord_step3}
                                        border="1px solid #90cdf4"
                                        width="320px"
                                        alt="英活・学習画面:step3"
                                    />
                                </Center>
                                <Heading size="sm">Step 4: 英文作成とAIによる添削機能</Heading>
                                <Text>
                                    日本語で与えられた文から、その単語を使って英文を作成します。作成した内容をAIが添削します。
                                </Text>
                                <Center>
                                    <Image
                                        src={studyWord_step4}
                                        border="1px solid #90cdf4"
                                        width="320px"
                                        alt="英活・学習画面:step4"
                                    />
                                </Center> 

                            </>
                        }
                    />                        

                    <ContentCard
                        title="セキュリティの強化"
                        cardImage={
                            <Image
                                src={signIn_short} 
                                width="80px"
                                alt="英活・サインイン画面"
                            />                    
                        }
                        summary={
                            "英活はユーザー認証およびプライバシー保護には万全の対策を施しています。安全安心に英活をお使い頂けるよう細心の注意を払っています。"
                        }
                        details={
                            <>
                                <Text>
                                    英活は様々なデバイスからアクセス可能なWebアプリです。スマートフォン、PC等のウェブブラウザーからサインインして使用します。ユーザー認証およびプライバシー保護には万全の対策を施しており、安全安心に英活をお使い頂けるよう細心の注意を払っています。
                                </Text>
                                <Text>
                            		ユーザー認証にはマイクロソフト社が提供するEntra IDサービスを使用しています。このユーザー認証システムは英活アプリにユーザーパスワード情報を一切伝えず認証を行うため、万一英活アプリが悪意ある第三者による攻撃を受けた場合でも、パスワード流出の危険が一切ありません。
                                </Text>
                                <Text>
                                    またこのユーザー認証システムは、マイクロソフト社のアカウントだけでなく、Googleアカウントを利用することもできます。既にお持ちのGoogleアカウントで英活にサインアップしていただき安心してご利用いただけます。
                                </Text>
                                <Center>
                                    <Image
                                        width="320px"
                                        src={signIn_short}
                                        border="1px solid #90cdf4"
                                        alt="英活・サインイン画面"
                                    />
                                </Center> 
                                <br/>
                                <Text>
                                    また各ユーザーが作成・編集したデータは、他のユーザーがアクセスできない場所に格納されます。個人作成データ漏洩の心配もありません。
                                </Text>
                            </>
                        }
                    />                        

                    <Box 
                        width="100%"
                        height="50px"
                    />


                                       
                </Stack> 
            </Stack>
        </Container>
    )
}

export default SingOutMainPage


