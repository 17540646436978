import React from "react";
import {
    Box,
    Button,
    Center,
    HStack,
    Stack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import { loginRequest } from "../authConfig"
import { useMsal } from '@azure/msal-react'
import { useCookies } from "react-cookie";

import { ReactComponent as GoogleSvg } from "../assets/icons/google.svg"
// import { ReactComponent as FacebookSvg } from "../assets/icons/facebook.svg"
import { getUserAgentInfo } from "../CommonFunction"

const LoginRequestDialog = (props) => {

    const { instance } = useMsal();
    const [cookies] = useCookies();

    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={props.isLoginRequestDialogOpen} 
            onClose={props.onLoginRequestDialogClose}
        >
            <ModalOverlay />
            <ModalContent
                maxW="340px"
            >
                <ModalHeader>
                    サインイン
                </ModalHeader>                
                <ModalCloseButton 
                    onClick={() => {
                        props.onLoginRequestDialogClose()
                    }}
                />                
                <ModalBody
                    m="16px 0px 0px 8px"
                    p="0px 8px 0px 8px"
                    style={{ maxHeight: "400px", overflowY: "auto"}}
                >
                    <Stack>
                        <HStack
                            onClick={async ()=>{
                                await instance.loginRedirect({
                                    ...loginRequest,
                                    prompt: cookies?.loginPrompt === "signUp" ? "create" : "select_account",
                                    // loginHint: 'google-accounts',
                                    domainHint: 'google.com',
                                }).catch((error) => console.log(error))
                                props.onLoginRequestDialogClose()
                            }}
                        >
                            <Box>
                                <GoogleSvg
                                    width="48px"
                                    height="48px"
                                />
                            </Box> 
                            <Box
                                style={{
                                    "font-weight": "400",
                                    "font-size": ".9375rem"
                                }}
                            >
                                Google で{cookies?.loginPrompt === "signUp" ? "サインアップ" : "サインイン"}
                            </Box>
                        </HStack>
                        {
                            getUserAgentInfo().os === "ios" ?
                                <Center
                                    style={{
                                        "font-weight": "400",
                                        "font-size": ".9375rem"
                                }}                                >
                                    ※現在 iOS では Google アカウントのみ<br/>サポートしています
                                </Center>
                                :
                                <></>
                        }
                        {/* <HStack
                            onClick={async ()=>{
                                await instance.loginRedirect({
                                    ...loginRequest,
                                    prompt: cookies?.loginPrompt === "signUp" ? "create" : "select_account",
                                    // loginHint: 'facebook-accounts',
                                    domainHint: 'facebook.com',
                                    //
                                    // authority: "https://facebook.com",
                                    // knownAuthorities: ["facebook.com"],
                                    // protocolMode: instance.ProtocolMode.OIDC,
                                    //  OIDCOptions: { "serverResponseType": instance.serverResponseType.QUERY, "defaultScopes": ["openid"] },
                                    // authorityMetadata: '{ "issuer": "https://www.facebook.com", "authorization_endpoint": "https://facebook.com/dialog/oauth/", "token_endpoint": "https://graph.facebook.com/oauth/access_token", "jwks_uri": "https://www.facebook.com/.well-known/oauth/openid/jwks/" }',
                                    
                                    // extraQueryParameters: {
                                    //     "domain_hint": "facebook.com",
                                    //     "domainHint": "facebook.com"
                                    // }
                                    

                                }).catch((error) => console.log(error))
                                props.onLoginRequestDialogClose()
                            }}
                        >
                            <Box>
                                <FacebookSvg
                                    width="48px"
                                    height="48px"
                                />
                            </Box> 
                            <Box
                                style={{
                                    "font-weight": "400",
                                    "font-size": ".9375rem"
                                }}
                            >
                                Facebook で{cookies?.loginPrompt === "signUp" ? "サインアップ" : "サインイン"}
                            </Box>
                        </HStack> */}
                    </Stack>
                </ModalBody>
                <ModalFooter
                    justifyContent="flex-start"
                >
                    <Center
                        width="100%"
                    >
                        <Button
                            colorScheme="gray"
                            onClick={() => {
                                props.onLoginRequestDialogClose()
                            }}
                        >
                            キャンセル
                        </Button>
                    </Center>
                </ModalFooter>
            </ModalContent>
        </Modal>        
    )
}

export default LoginRequestDialog