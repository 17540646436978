import React, { useState, useReducer, useEffect, useCallback, useRef } from 'react'
import {
    getValueFromWord,
    chooseSelectedMeaningSentence,
    validateSelectedMeaningId,
    permutateWordElements
} from "../WordDataFunction"
import {
    getSases
} from "../CommonFunction"
import DisplayWord from './display/DisplayWord'
import { buildDisplayWordProps } from './display/WordDataPropsBuilder'
import QuizWordNormal from './quiz/QuizWordNormal'
import QuizWordAdvance from "./quiz/QuizWordAdvance"
import CompleteQuiz from './quiz/CompleteQuiz'
import StudyWord from './study/StudyWord'
import NoWordToQuiz from "./quiz/NoWordToQuiz"
import DefaultPage from './DefaultPage'
import WordHistory from './history/WordHistory'
import WordList from './list/WordList'
import { buildStudyWordProps } from "./study/StudyWordPropsBuilder"
import { buildQuizWordProps } from "./quiz/QuizWordPropsBuilder"
import { useUserContext } from '../context/userContext'
import useApi from "../hooks/useApi"

const PageController = (props) => {

    const { 
        getQuizWords,
        getUserWordHistory
    } = useApi()

    const {
        getUserId,
        getQuizMode
    } = useUserContext()
    
    ///////////////////////////////////////////////////////

    ////////////////////////////////////////////////////
    // SubApp launcher
    const [, setRequestId] = useState(0)

    // back to default home page
    const backToHome = () => {
        dispatch({
            type: "backToHome"
        })
    }

    // quiz lancher
    const launchQuiz = async () => {
        const requestId = crypto.randomUUID()
        setRequestId(requestId)
        const quizWords = await getQuizWords("getQuizWords", requestId)

        if (quizWords) {
            setRequestId(null)
            
            // console.log(`quizData: ${JSON.stringify(quizData)}`)
            let handler = "QuizWordNormal"
            let mode = "normal"
            if (getQuizMode() === "advanced") {
                handler = "QuizWordAdvanced"
                mode = "advanced"
            }

            dispatch({
                type: "quizInit",
                data: {
                    previous: {},
                    current: {
                        handler,
                        mode,
                        isRandom: false,
                        quizWords
                    }
                }
            })
        }
    }

    // wordList launcher
    const launchWordList = () => {
        dispatch({
            type: "wordList"
        })
    }

    // wordHistory launcher
    const launchWordHistory = async () => {
        const requestId = crypto.randomUUID()
        setRequestId(requestId)
        // ignoreGetHistoryRef.current = true
        const dailyGroupHistory = await getUserWordHistory(requestId)

        if (dailyGroupHistory) {
            setRequestId(null)
            dispatch({
                type: "wordHistory",
                data: {
                    previous: {},
                    current: {
                        dailyGroupHistory
                    }
                }
            })
        }
    }

    useEffect(() => {
        // preprocess in case the action.type starts with "to"
        if (props.launchSubApp) {
            const handler = getHandler(stateStack)

            if (["QuizWordNormal", "QuizWordAdvance"].includes(handler)) {
                dispatch({type:"browserBack"})
            } else {
                switch(props.launchSubApp) {
                    case "toHome":
                        backToHome()
                        break
                    case "toQuiz":
                        launchQuiz()
                        break
                    case "toWordList":
                        launchWordList()
                        break
                    case "toWordHistory":
                        launchWordHistory()
                        break
                    default:
                        break
                }
            }
        }
        props.setLaunchSubApp(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.launchSubApp])    

    /////////////////////////////////////////////////////////////////////////////
    // format of 'state'
    // const state = {
    //     handler: "",                     // page to render the original or complete data
    //     data: {                          // the format depends on the handler
    //         ...
    //     },                     
    // }

    const getHandler = (stateStack) => {
        if (Array.isArray(stateStack) && stateStack.length > 0) {
            return stateStack[0].handler
        } else {
            return ""
        }
    }

    const getData = (stateStack) => {
        if (stateStack.length > 0) {
            return stateStack[0].data
        } else {
            return null
        }
    }

    const popState = (stateStack) => {
        let state = null
        if (stateStack.length > 0) {
            while (true) {
                state = stateStack.shift()
                if (stateStack.length === 0) {
                    return state
                } else if (state.handler !== getHandler(stateStack)) {
                    return state
                } else {
                    // this is a workaround having duplicated states with <React.StrictMode/>
                    if (state.handler === "DisplayWord"
                            && state.data.displayWord.mode === "browseWord"
                            && getData(stateStack).displayWord.mode === "browseWord"
                            && getValueFromWord(getData(stateStack).displayWord.wordData, "wordId") === getValueFromWord(state.data.displayWord.wordData, "wordId")) {
                        // continue
                    } else {
                        return state
                    }
                }
            }
        }
        return state
    }

    const clearStateStack = (stateStack) => {
        stateStack.splice(0)
    }

    const setCompleteQuiz = (stateStack) => {
        const handler = getHandler(stateStack)
        if (handler.startsWith("QuizWord")) {
            const state = stateStack.shift()
            stateStack.unshift({
                handler: "CompleteQuiz",
                data: {...state.data}
            })
        } else {
            throw new Error(`stateStack[0].handler is ${stateStack.length > 0 ? stateStack[0].handler : null}`)
        }
    }
    const getDisplayWordModeForInit = (wordData) => {

        if (Object.keys(wordData).length === 0) {
            return "empty"
        } else {
            const isNew = getValueFromWord(wordData, "isNew")
            const isUserOriginWord = getValueFromWord(wordData, "wordOrigin") === "user"
            const hasValidSelectedMeaningId = validateSelectedMeaningId(wordData)

            if (isNew) {
                if (isUserOriginWord) {
                    return "registerNewUserWord"
                } else {
                    return  "readyToEditWordElement"
                }
            } else if (!hasValidSelectedMeaningId) {
                return "readyToEditWordElement"
            } else {
                return "browseWord"
            }            
        }
    }

    const dedupeDisplayWordStack = (newStateStack, wordData) => {
            // to avoid duplicated state stack
        if (getHandler(newStateStack) === "DisplayWord"
                && getData(newStateStack).displayWord.mode === "browseWord"
                && getValueFromWord(getData(newStateStack).displayWord.wordData, "wordId") === getValueFromWord(wordData, "wordId")) {
            popState(newStateStack)
        }

        return newStateStack
    }

    ////////////////////////////////////////////////////
    // expected format of 'action'
    //
    // action = {
    //    type: [action type: string],          // action type
    //    data: {
    //        previous: {                       // data for previous stateStatck if necessarily
    //            ...
    //        },
    //        current: {                        // data for current stateStack
    //            ...
    //        } 
    //    }
    // }

    const nextFunc = (stateStack, action) => {
        let newStateStack = [...stateStack]

        // clean up useless stacks
        while (newStateStack.length > 0) {
            if (getHandler(newStateStack) === "DisplayWord"
                && getData(newStateStack).displayWord.mode !== "browseWord") {
                popState(newStateStack)
            } else if (getHandler(newStateStack) === "DefaultPage") {
                popState(newStateStack)
            } else {
                break
            }
        }

        if (action.type === "browserBack") {
            const handler = getHandler(newStateStack)
            const data = getData(newStateStack)

            if (["QuizWordNormal", "QuizWordAdvance"].includes(handler)
                    && data.answers.length > 0) {
                setCompleteQuiz(newStateStack)
            } else {
                popState(newStateStack)
            }
        } else if (action.type === "displayWordSwitchToEdit") {
            const wordData = action.data.current.wordData
            const isEditted = action.data.current.isEditted

            newStateStack.unshift({
                handler: "DisplayWord",
                data: {
                    displayWord: {
                        wordData,
                        mode: "readyToEditWordElement",
                        editDialogParams: {isOpen: false},
                        isEditted          // taking over the previous value
                    },
                    search: {
                        ...action.data.previous.search
                    }
                }
            })              
        } else if (action.type === "displayWordSwitchToBrowse") {
            const wordData = action.data.current.wordData
            const isEditted = action.data.current.isEditted

            newStateStack.unshift({
                handler: "DisplayWord",
                data: {
                    displayWord: {
                        wordData,
                        mode: "browseWord",
                        editDialogParams: {isOpen: false},
                        isEditted          // taking over the previous value
                    },
                    search: {
                        ...action.data.previous.search
                    }
                }
            })                 
        } else if (action.type === "displayWordEditDialogOpened") {
            const wordData = action.data.current.wordData
            const editDialogParams = action.data.current.editDialogParams
            const isEditted = action.data.current.isEditted

            newStateStack.unshift({
                handler: "DisplayWord",
                data: {
                    displayWord: {
                        wordData,
                        mode: "editWordElement",
                        editDialogParams,
                        isEditted          // taking over the previous value
                    },
                    search: {
                        ...action.data.previous.search
                    }
                }
            })              
        } else if (action.type === "displayWordEditDialogCommitted") {
            if (action.data.current?.subMode === "wordDelete"
                && ["DisplayWord"].includes(getHandler(newStateStack))) {

                // back to DefaultPage
                popState(newStateStack)
                newStateStack.unshift({
                    hander: "DefaultPage",
                    data: {}
                })
            } else {
                const wordData = permutateWordElements(action.data.current.wordData)
                newStateStack = dedupeDisplayWordStack(newStateStack, wordData)
                newStateStack.unshift({
                    handler: "DisplayWord",
                    data: {
                            displayWord: {
                            wordData,
                            mode: "readyToEditWordElement",
                            editDialogParams: {isOpen: false},
                            isEditted: true
                        },
                        search: {
                            ...action.data.previous.search
                        }
                    }
                })
            }              
        } else if (action.type === "displayWordEditDialogCanceled") {
            const wordData = action.data.current.wordData
            const isEditted = action.data.current.isEditted

            const isNew = getValueFromWord(wordData, "isNew")
            const isUserOriginWord = getValueFromWord(wordData, "wordOrigin") === "user"

            let mode
            if (isNew && isUserOriginWord && !isEditted) {
                mode = "registerNewUserWord"
            } else {
                mode = "readyToEditWordElement"
            }

            newStateStack.unshift({
                handler: "DisplayWord",
                data: {
                    displayWord: {
                        wordData,
                        mode,
                        editDialogParams: {isOpen: false},
                        isEditted,          // taking over the previous value
                    },
                    search: {
                        ...action.data.previous.search
                    }
                }
            })
        } else if (action.type === "displayWordSaved") {
            const wordData = action.data.current.wordData
            newStateStack = dedupeDisplayWordStack(newStateStack, wordData)
            newStateStack.unshift({
                handler: "DisplayWord",
                data: {
                    displayWord: {
                        wordData,
                        mode: "browseWord",
                        editDialogParams: {isOpen: false},
                        isEditted: false
                    },
                    search: {
                        ...action.data.previous.search
                    }
                }
            })            
        } else if (action.type === "quizInit") {
            clearStateStack(newStateStack)
            if (action.data.current.quizWords.length > 0) {
                newStateStack.unshift({
                    handler: action.data.current.handler,
                    data: {
                        ...action.data.current,
                        answers:[]
                    }
                })
                console.log("")
            } else {
                newStateStack.unshift({
                    handler: "NoWordToQuiz",
                    data: {
                        mode: action.data.current.mode
                    }
                })  
            }
        } else if (action.type === "quizAnswer") {
            const handler = getHandler(newStateStack)
            if (handler.startsWith("QuizWord")) {
                const data = getData(newStateStack)

                if (data.answers.length === 0 ||
                    data.answers[data.answers.length - 1].cId !== action.data.current.cId) {
                    data.answers.push(action.data.current)
                }
                
                if (data.quizWords.length <= data.answers.length) {
                    // CompleteQuiz
                    setCompleteQuiz(newStateStack)
                }
            } else {
                throw new Error(`${getHandler(newStateStack)} is an invalid handler`)
            }
        } else if (action.type === "studyInit") {
            if (getHandler(newStateStack) === "DisplayWord") {
                const wordData = action.data.current.wordData

                // update wordData on the top of the stack first
                const prevWordData = getData(newStateStack).selectedWordData
                if (getValueFromWord(wordData, "wordId") === getValueFromWord(prevWordData, "wordId")) {
                    getData(newStateStack).selectedWordData = wordData
                }
                getData(newStateStack).scrollPosition = action.data.previous.scrollPosition

                const selectedMeaningSentence = chooseSelectedMeaningSentence(wordData)
                
                newStateStack.unshift({
                    handler: "StudyWord",
                    data: {
                        wordData,
                        selectedMeaningSentence
                    }
                })                  
            } else {
                throw new Error(`${getHandler(newStateStack)} is an invalid handler`)
            }
        } else if (action.type === "studyComplete") {
            popState(newStateStack)
            if (["DisplayWord", "WordHistory"].includes(getHandler(newStateStack))) {
                // DisplayWord or WordHistory
            } else {
                throw new Error(`${getHandler(newStateStack)} is an invalid handler`)
            }
        } else if (action.type === "displayWordClose") {
            popState(newStateStack)
            if (["WordHistory", "CompleteQuiz"].includes(getHandler(newStateStack))) {
                // WordHistory, or CompleteQuiz
            } else {
                throw new Error(`${getHandler(newStateStack)} is an invalid handler`)
            }
        } else if (action.type === "selectWord") {
            if (["WordHistory", "WordList"].includes(getHandler(newStateStack))) {
                getData(newStateStack).viewSettings = action.data.previous.viewSettings
                getData(newStateStack).dataRepo = action.data.previous.dataRepo
                getData(newStateStack).scrollPosition = action.data.previous.scrollPosition
            } else if (["CompleteQuiz"].includes(getHandler(newStateStack))) {
                getData(newStateStack).scrollPosition = action.data.previous.scrollPosition
            } else {
                throw new Error(`stateStack.length <= 0`)
            }
            const wordData = action.data.current.wordData

            newStateStack.unshift({
                handler: "DisplayWord",
                data: {
                    displayWord: {
                        wordData,
                        mode: "browseWord",
                        editDialogParams: {isOpen: false},
                        isEditted: false
                    },
                    search: {
                        query: getValueFromWord(wordData, "displayName"),
                        selectedWordData: wordData,
                        candidates:[],
                        hasPerfectMatch: true
                    }
                }
            })             
        } else if (action.type === "search") {

            let state    // referring to 'current' or 'previous'
            if (Object.keys(action.data.current).length > 0) {
                state = action.data.current
            } else if (Object.keys(action.data.previous).length > 0) {
                state = action.data.previous
            } else {
                throw new Error(`action.type: ${action.type} does not have data either action.data.current or action.data.previous`)
            }
            
            const wordData = state.searchResult.selectedWordData
            const mode = getDisplayWordModeForInit(wordData)
            
            // to avoid duplicated state stack
            if (getHandler(newStateStack) === "DisplayWord"
                && getData(newStateStack).displayWord.mode === mode
                && getValueFromWord(getData(newStateStack).displayWord.wordData, "wordId" === getValueFromWord(wordData, "wordId"))) {
                popState(newStateStack)
            }                

            newStateStack.unshift({
                handler: "DisplayWord",
                data: {
                    displayWord: {
                        wordData,
                        mode,
                        editDialogParams: {isOpen: false},
                        isEditted: false
                    },
                    search: {
                        query: state.query,
                        selectedWordData: wordData,
                        candidates: state.searchResult.candidates,
                        hasPerfectMatch: state.searchResult.candidates.some((candidate) => candidate.matchStatus === "perfect")
                    }
                }
            })
        } else if (action.type === "wordHistory") {
            newStateStack.unshift({
                handler: "WordHistory",
                data: {
                    dailyGroupHistory: action.data.current.dailyGroupHistory
                }
            })
            console.log("")
        } else if (action.type === "wordDelete") {
            if (["DisplayWord"].includes(getHandler(newStateStack))) {
                popState(newStateStack)
            } else {
                throw new Error(`${getHandler(newStateStack)} is an invalid handler`)
            }
        } else if (action.type === "wordList") {
            newStateStack.unshift({
                handler: "WordList",
                data: {}
            })
        } else if (action.type === "error") {
            popState(newStateStack)
            newStateStack.unshift({
                handler: "DefaultPage",
                data: {}
            })
        } else if (action.type === "dwell") {
            // do nothing, stay on the same page
        } else if (action.type === "backToHome") {
            if (newStateStack.length > 0) {
                newStateStack.unshift({
                    handler: "DefaultPage",
                    data: {}
                })
            }
        } else if (action.type === "clickLink") {
            const wordData = action.data.current.wordData

            newStateStack.unshift({
                handler: "DisplayWord",
                data: {
                    displayWord: {
                        wordData,
                        mode: "browseWord",
                        editDialogParams: {isOpen: false},
                        isEditted: false
                    },
                    search: {
                        query: getValueFromWord(wordData, "displayName"),
                        selectedWordData: wordData,
                        candidates:[],
                        hasPerfectMatch: true
                    }
                }
            })
        } else {
            if (newStateStack.length > 0) {
                newStateStack.unshift({
                    handler: "DefaultPage",
                    data: {}
                })
            }
        }

        return newStateStack
    }

    const [stateStack, dispatch] = useReducer(nextFunc, [])

    //////////////////////////////////////////////////////////////
    // Browser back handling

    const timeRef = useRef(0)
    const blockBrowserBack = useCallback((e) => {
        window.history.go(1)

        // in case of React.StrictMode (for INT), ignore the 2nd execution.
        const elapsedTime = Date.now() - timeRef.current
        if (process.env.REACT_APP_ENV === "PROD"
               || elapsedTime >= 1000) {
            dispatch({type: "browserBack"})
        }
        timeRef.current = Date.now()
    }, [])
    
    useEffect(() => {
        window.history.pushState(null, '', window.location.href)
        window.addEventListener('popstate', blockBrowserBack)
        return () => {
            window.removeEventListener('popstate', blockBrowserBack)
        }
    }, [blockBrowserBack])


    ///////////////////////////////////////////////////
    // When getHander(stateStack) was directly used in the return clause,
    // "Uncaught TypeError: getHandler(...) is undefined" occured.
    // getData(...) caused the same error too.
    // As the workaround, those values are assigned with useState(), 
    // and specificed in useEffect()
    // Since headState.handler could be undefined occasionally somehow,
    // headState.hander !== undefined is neccessary

    const [headState, setHeadState] = useState({
        handler:"DefaultPage",
        data: {}
    })

    useEffect(() => {
        console.log(`getHandler(stateStack): ${getHandler(stateStack)}`)
        setHeadState({
            handler: getHandler(stateStack),
            data: getData(stateStack)
        })
    }, [stateStack])

    ///////////////////////////////////////////////////


    return (
        headState.handler && headState.handler.startsWith("QuizWordNormal") ?
            <QuizWordNormal
                params={buildQuizWordProps(
                    headState.data,
                    getSases()
                )}
                dispatch={dispatch}
                setLaunchSubApp={props.setLaunchSubApp}
            />
            :
            headState.handler && headState.handler.startsWith("QuizWordAdvance") ?
                <QuizWordAdvance
                    params={buildQuizWordProps(
                        headState.data,
                        getSases()
                    )}
                    dispatch={dispatch}
                    setLaunchSubApp={props.setLaunchSubApp}
                />
                :
                headState.handler && headState.handler === "StudyWord" ? 
                    <StudyWord
                        params={buildStudyWordProps(
                            headState.data.wordData,
                            headState.data.selectedMeaningSentence,
                            getSases()
                        )}
                        dispatch={dispatch}
                        returnActionType="backToQuiz"
                        setLaunchSubApp={props.setLaunchSubApp}
                    />
                    :
                    headState.handler && headState.handler.startsWith("CompleteQuiz") ?
                        <CompleteQuiz
                            data={headState.data}
                            dispatch={dispatch}
                            setLaunchSubApp={props.setLaunchSubApp}
                        />
                        :
                        headState.handler && headState.handler.startsWith("NoWordToQuiz") ?
                            <NoWordToQuiz
                                mode={headState.handler.endsWith("Normal") ? "normalLevelRandom" : "advancedLevelRandom"}
                                dispatch={dispatch}
                                setLaunchSubApp={props.setLaunchSubApp}
                            />
                            :
                            headState.handler && headState.handler === "DisplayWord" ?
                                <DisplayWord
                                    displayWordParams={buildDisplayWordProps(
                                        headState.data.displayWord.wordData,
                                        headState.data.displayWord.editDialogParams,
                                        headState.data.displayWord.mode,
                                        headState.data.displayWord.isEditted,
                                        getUserId(),
                                        getSases()
                                    )}
                                    searchParams={headState.data.search}
                                    scrollPosition={headState.data?.scrollPosition}
                                    dispatch={dispatch}
                                    setLaunchSubApp={props.setLaunchSubApp}
                                />
                                :
                                headState.handler && headState.handler === "WordHistory" ?
                                    <WordHistory
                                        dailyGroupHistory={headState.data.dailyGroupHistory}
                                        scrollPosition={headState.data?.scrollPosition}
                                        dispatch={dispatch}
                                        setLaunchSubApp={props.setLaunchSubApp}
                                    />
                                    :
                                    headState.handler && headState.handler === "WordList" ?
                                        <WordList
                                            viewSettings={headState.data?.viewSettings}
                                            dataRepo={headState.data?.dataRepo}
                                            scrollPosition={headState.data?.scrollPosition}
                                            dispatch={dispatch}
                                            setLaunchSubApp={props.setLaunchSubApp}
                                        />
                                        :
                                        <DefaultPage
                                            query={""}
                                            dispatch={dispatch}
                                            backToHome={backToHome}
                                            launchQuiz={launchQuiz}
                                            launchWordList={launchWordList}
                                            launchWordHistory={launchWordHistory}
                                            setLaunchSubApp={props.setLaunchSubApp}

                                        />
    )
}

export default PageController