import React from "react";
import { useFormik } from "formik";
import {
    Box,
    FormControl,
    Stack
} from "@chakra-ui/react";
import "../../App.css";
import SearchBox from "./SearchBox";
import useApi from "../../hooks/useApi"
import HeaderComponent from "../HeaderComponent";

const SearchWord = (props) => {
    const { 
        searchWord
    } = useApi()

    const onSubmit = async (values) => {
        const requestId = crypto.randomUUID()
        props.setRequestId(requestId)
        const searchResult = await searchWord(values.query, requestId)
        if (searchResult) {
            props.setRequestId(null)
            props.dispatch({
                type: "search",
                data: {
                    previous: {},
                    current: {
                        query: values.query,
                        searchResult
                    }
                }
            })
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { query: props.query },
        onSubmit
    })

    return (
        <>
            <Stack
                margin="0%"
                textAlign="left"
                rounded="md"
                width="100%"
            >
                <HeaderComponent
                    setLaunchSubApp={props.setLaunchSubApp}
                    content={
                        <form onSubmit={formik.handleSubmit}>
                            <FormControl
                                isInvalid={!!formik.errors.query && formik.touched.query}
                            >
                                <Box>
                                    <SearchBox
                                        inputId={"query"}
                                        formik={formik}
                                        placeholder={"登録したい単語をタイプ"}
                                        buttonLabel={"検索"}
                                        onClickFunc={() => {onSubmit(formik.values)}}
                                    />
                                    <Box
                                        color="red"
                                        textAlign="left"
                                        ml="4"
                                    >
                                        {
                                            formik.touched.query && formik.errors.query ?
                                                formik.errors.query
                                                :
                                                null
                                        }
                                    </Box>
                                </Box>
                            </FormControl>
                        </form>
                    }
                />
            </Stack>
        </>
    )
}

export default SearchWord

