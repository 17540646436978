import React from "react";
import {
    Spinner,
    Center,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from '@chakra-ui/react'

const FullCoverSpinner = (props) => {

    return (
        <Modal 
            isOpen={props.isOpen} 
            onClose={props.isClose}
            isCentered={true}
        >
            <ModalOverlay />
            <ModalContent
                bg="rgba(0, 0, 0, 0)"
            >
                <ModalBody>
                    <Center>
                        <Spinner
                            thickness='20px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='blue.500'
                            margin="10% 0% 0% 0%"
                            height="150px"
                            width="150px"
                        />
                    </Center>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default FullCoverSpinner