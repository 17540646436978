import React, { useState, useEffect } from "react"
import { 
    ChakraProvider,
    Flex,
    Box,
    Center,
    useDisclosure
} from "@chakra-ui/react"
import './App.css';
import SignOutMainPage from "./components/SignOutMainPage";
import NavigationBar from "./components/NavigationBar"
import Footer from "./components/Footer"
import { useCookies } from "react-cookie";

import { 
    MsalProvider,
    AuthenticatedTemplate,
    useMsal,
    UnauthenticatedTemplate,
    useIsAuthenticated 
} from '@azure/msal-react';
import RoutesSettings from "./RoutesSettings";

import useApi from "./hooks/useApi"
import FullCoverSpinner from "./components/FullCoverSpinner"
import { getUserAgentInfo } from "./CommonFunction"


/**
 * Most applications will need to conditionally render certain components based on whether a user is signed in or not. 
 * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will 
 * only render their children if a user is authenticated or unauthenticated, respectively. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const MainContent = () => {
	/**
	 * useMsal is hook that returns the PublicClientApplication instance,
	 * that tells you what msal is currently doing. For more, visit:
	 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
	 */
	const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const {
        registerUser,
        retrieveUser,
        getAvatarImage,
        getWordStatus
    } = useApi()

    //////////////////////////////////////////////////////
    // browser support
    const [userAgentInfo, setUserAgentInfo] = useState({
        isSupported: false,
        os: "unknown",
        browserName: "unknown",
        browserVersion: "unknown"
    })
    
    //////////////////////////////////////////////////////
    const [cookies] = useCookies();

    //////////////////////////////////////////////////////
    // signInStatus:
    //   "signedIn"
    //   "notSignedIn"
    //   "unknown"

    const [signInStatus, setSignInStatus] = useState("unknown")
    const [errorMessages, setErrorMessages] = useState([])

    const { 
        isOpen: isSpinnerOpen,
        onOpen: onSpinnerOpen,
        onClose: onSpinnerClose
    } = useDisclosure()
    //////////////////////////////////////////////////////

    useEffect(() => {
        const uaInfo = getUserAgentInfo()
        setUserAgentInfo({...uaInfo})

        if (uaInfo.isSupported) {
            (async() => {

                setSignInStatus("unknown")
                onSpinnerOpen()
                await instance.initialize()
                let statusSuccess = false

                try {
                    if (isAuthenticated) {
                        const activeAccount = instance.getActiveAccount()
                        const userId = activeAccount?.localAccountId
                        const email = activeAccount?.username                        
                        const displayName = activeAccount?.name

                        if (!cookies?.loginPrompt) {
                            const response = await retrieveUser()
                            statusSuccess = response.status === "success"

                        } else if (cookies?.loginPrompt === "signUp") {
                            const response = await registerUser(email, displayName)

                            if (response.status === "success") {
                                statusSuccess = true
                            } else {
                                if (response.results?.isEmailInUse) {
                                    setErrorMessages([
                                        `Email: ${email} は別のアカウントに既に使われています`,
                                        "別のメールアドレスを使ってアカウント作成してください。"
                                    ])                         
                                } else {
                                    setErrorMessages([
                                        "アカウント作成中にエラーが発生しました",
                                        "もう一度アカウント作成をお試しください。",
                                        `エラー: ${response.results.message}`,
                                        `userId: ${userId}, email : ${email}`
                                    ])                         
                                }
                                statusSuccess = false
                            }
                        } else if (cookies?.loginPrompt === "signIn") {
                            const response = await retrieveUser()

                            if (response.status === "success") {
                                statusSuccess = true
                            } else {
                                if ("isUserIdRegistered" in response.results && !response.results.isUserIdRegistered) {
                                    setErrorMessages([
                                        "アカウントが登録されていません",
                                        "サインインにご使用になったアカウントはまだ登録されていません。",
                                        "最初に [アカウント作成] を行ってください。"
                                    ])                         

                                } else {
                                    setErrorMessages([
                                        "サインイン中にエラーが発生しました",
                                        "もう一度サインインをお試しください。",
                                        `エラー: ${response.results.message}`,
                                        `userId: ${userId}, email : ${email}`
                                    ])                         
                                }
                                statusSuccess = false
                            }
                        }
                    } else {
                        statusSuccess = false
                    }
                } catch (error) {
                    console.log(`error: ${error}`)
                    statusSuccess = false
                }

                if (statusSuccess) {
                    await getWordStatus()
                    await getAvatarImage()
                    setSignInStatus("signedIn")
                } else {
                    setSignInStatus("notSignedIn")
                }

                onSpinnerClose()
            })()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, instance])

    //////////////////////////////////////////////////////
    // for subapp launcher

    const [launchSubApp, setLaunchSubApp] = useState(null)

    //////////////////////////////////////////////////////

	return (
		<Flex
            w="100vw"
            h="100vh"
            justifyContent="center"
        >
            <Flex
                direction="column"
                maxWidth="810px"
                width="100%"
            >
                <NavigationBar 
                    signInStatus={signInStatus}
                    setLaunchSubApp={setLaunchSubApp}
                />			
                <AuthenticatedTemplate>
                    <Box
                        flex="1"
                        width="100%"
                    >
                        <FullCoverSpinner
                            isOpen={isSpinnerOpen} 
                            onClose={onSpinnerOpen}
                        />
                        {
                            signInStatus === "notSignedIn" ?
                                <SignOutMainPage
                                    errorMessages={errorMessages}
                                />
                                :
                                signInStatus === "signedIn" ?
                                    <RoutesSettings
                                        signInStatus={signInStatus}
                                        errorMessages={[]}
                                        setLaunchSubApp={setLaunchSubApp}
                                        launchSubApp={launchSubApp}
                                    />
                                    :
                                    // signInStatus === "unknown" ?
                                    <></>
                        }
                    </Box>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                {
                    !userAgentInfo.isSupported ? (
                        <Box
                            width="100%"
                            height="100%"
                        >
                        {
                            userAgentInfo.browserName === "unknown" ? (
                                <div>英活はお使いのブラウザをサポートしていません。別のブラウザでご利用ください</div>
                            ): (
                                ["ie"].includes(userAgentInfo.browserName) ? (
                                    <div>英活は {userAgentInfo.browserName} ブラウザをサポートしていません。<br/>別のブラウザでご利用ください</div>
                                    ) : (
                                        <>
                                            <Center>英活は {userAgentInfo.browserName} バージョン{userAgentInfo.browserVersion} をサポートしていません。</Center>
                                            <Center>ブラウザを最新バージョンにアップデートしてから、<br/>もしくは別のブラウザでご利用ください。</Center>
                                        </>
                                    )
                            )
                        }
                        </Box>
                    ) : (
                        signInStatus === "notSignedIn" ?
                            <RoutesSettings
                                signInStatus={signInStatus}
                                errorMessages={errorMessages}
                            />
                            :
                            <></>
                    )
                }
                </UnauthenticatedTemplate>
                <Footer />
            </Flex>
		</Flex>
	);
};

function App({ instance }) {
	return (
		<ChakraProvider>
			<MsalProvider instance={instance}>
				<MainContent />
			</MsalProvider>
		</ChakraProvider>
	);
};

export default App;
