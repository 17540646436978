import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Divider,
    VStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    useDisclosure
} from "@chakra-ui/react"
import { Pie } from "react-chartjs-2"
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js'
import { Stage, Layer, Circle } from 'react-konva';
import CountUp from "react-countup"

import {
    getEpisodeImageUrl
} from "../../WordDataFunction"
import {
    getSases
} from "../../CommonFunction"
import useApi from "../../hooks/useApi";
import BackButton from "../BackButton";
import WordSnippetDialog from "../WordSnippetDialog"
import FullCoverSpinner from "../FullCoverSpinner";
import HeaderComponent from "../HeaderComponent";

Chart.register(ArcElement, Tooltip, Legend);

const CompleteQuiz = (props) => { 
    const { 
        getQuizWords
    } = useApi()

    const { 
        isOpen: isSpinnerOpen,
        onOpen: onSpinnerOpen,
        onClose: onSpinnerClose
    } = useDisclosure()    

    // const [isSnippetOpen, setIsSnippetOpen] = useState(false)
    const [selectedWord, setSelectedWord] = useState(
        {
            cId: "",
            wordId: "",
            displayName: "",
            meaning: "",
            wordImageUrl: "",
            index: -1
        }
    )

    const onClickForNextQuiz = async () => {
        onSpinnerOpen()
        const quizWords = await getQuizWords("getQuizWords")

        if (quizWords) {
            onSpinnerClose()
            props.dispatch({
                type: "quizInit",
                data: {
                    previous: {},
                    current: {
                        ...props.data,
                        quizWords
                    }
                }
            })
        }
    }

    const [quizResult, setQuizResult] = useState({
        quizCount: 0,
        correctCount: 0,
        correctRate: 0,
        results: [],
        rnd: Math.floor(Math.random() * 10)
    })

    useEffect(() => {
        const correctCount = props.data.answers.filter((a) => a.isCorrect).length

        setQuizResult({
            ...quizResult,
            quizCount: props.data.answers.length,
            correctCount,
            correctRate: correctCount / (props.data.answers.length > 0 ? props.data.answers.length : 1)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.data])

    const resultDetail = (
        <TableContainer>
            <Table
                variant='simple'
            >

                <Thead>
                    <Tr>
                        <Th width="20px" p="5px">結果</Th>
                        <Th p="5px">単語</Th>
                        <Th flex="1" p="5px">意味</Th>
                    </Tr>
                </Thead>

                <Tbody>
                    {
                        props.data.answers.map((r, i) => (
                            <Tr
                                onClick={() => {
                                    setSelectedWord(
                                        {
                                            cId: r.cId,
                                            wordId: r.wordId,
                                            displayName: r.displayName,
                                            meaning: r.meaning,
                                            wordImageUrl: getEpisodeImageUrl(r.wordId, getSases()),
                                            index: i
                                        }
                                    )
                                    // setIsSnippetOpen(true)
                                }}
                                key={i}
                            >
                                <Td
                                    color={r.isCorrect ? "blue.500" : "red.500"}
                                    p="5px"
                                    textAlign="center"
                                >
                                    {r.isCorrect ? "◎" : "×"}
                                </Td>
                                <Td
                                    p="5px"
                                >
                                    {r.displayName}
                                </Td>
                                <Td
                                    p="5px"
                                >
                                    {r.meaning}
                                </Td>
                            </Tr>
                        ))
                    }
                </Tbody>
            </Table>
        </TableContainer>
    )

    ///////////////////////////////////////////////////
    // scrolling
    const getScrollPosition = () => {
        return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    }

    useEffect(() => {
        // setIsSnippetOpen(false)
        setTimeout(()=> {
            window.scrollTo(0, 0)
        }, 100)
        // window.scrollTop = props.scrollPosition
    }, [])
    ///////////////////////////////////////////////////

    const styles = {
        pieContainer: {
            position: "relative",
            width: "100%",
            height: "100%"
        },
        overlayCircle: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none'
        },
        overlayCountUp: {
            position: 'absolute',
            top: '49%',
            left: '49%',
            transform: 'translate(-50%, -50%)',
            fontSize: '100px',
            color: '#63b3ed',
        }        
    }

    const correctPercentage = Math.round(quizResult.correctRate * 100)

    const pieData = {
        maintainAspectRatio: false,
        responsive: false,
        labels: ["correct", "incorrect"],
        datasets: [
            {
            data: [correctPercentage, 100 - correctPercentage],
            backgroundColor: ["#63b3ed", "#ebf8ff"],
            hoverBackgroundColor: ["#DDDDDD"]
            }
        ]
    }

    const pieOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            }
        },
        elements: {
            arc: {
                borderWidth: 0
            }
        }
    }

    return (
        <>
            <VStack
                margin="0%"
                textAlign="left"
                p="1"
                rounded="md"
                width="100%"
            >
                <FullCoverSpinner
                    isOpen={isSpinnerOpen} 
                    onClose={onSpinnerOpen}
                />

                <HeaderComponent
                    setLaunchSubApp={props.setLaunchSubApp}
                    content={
                        <Box
                            fontSize="xl"
                            m="0px 0px 0px 12px"
                        >
                            クイズ終了
                        </Box>
                    }
                />                   

                <Box
                    width="320px"
                    height="320px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"                    
                    m="0px 0px 12px 0px"
                >
                    <div 
                        style={styles.pieContainer}
                    >
                        <Pie
                            data={pieData}
                            options={pieOptions}
                            position="absolute"
                            // ref={input => {
                            //     // chartInstance = input;
                            // }}
                        />

                        <div style={styles.overlayCircle}>
                            <Stage 
                                width={320}
                                height={320}
                            >
                                <Layer>
                                    <Circle
                                        x={160}
                                        y={160}
                                        radius={90}
                                        fill="white"
                                    />
                                </Layer>
                            </Stage>
                        </div>

                        <div style={styles.overlayCountUp}>
                            <CountUp
                                end={correctPercentage}
                                duration={1.4}
                            />
                        </div>                    
                    </div>

                </Box>                

                <Box>
                    {quizResult.quizCount}問中{quizResult.correctCount}正解
                </Box>
                <Box>
                    正解率は{correctPercentage}%でした。
                </Box>
                <Divider/>
                {resultDetail}
                <Box>
                    <Button
                        onClick={() => onClickForNextQuiz()}
                        colorScheme="blue"
                    >
                        クイズを続ける
                    </Button>
                </Box>

                <WordSnippetDialog
                    cId={selectedWord.cId}
                    wordId={selectedWord.wordId}
                    displayName={selectedWord.displayName}
                    meaning={selectedWord.meaning}
                    isStarred={selectedWord.isStarred}
                    setStar={()=>{}}
                    wordImageUrl={selectedWord.wordImageUrl}
                    selectedWord={selectedWord}
                    setSelectedWord={setSelectedWord}
                    onSpinnerOpen={onSpinnerOpen}
                    onSpinnerClose={onSpinnerClose}
                    getScrollPosition={getScrollPosition}
                    dispatch={props.dispatch}
                />                    

                <Box
                    width="100%"
                    m="20px 0px 20px 40px"
                >
                    <BackButton
                        dispatch={props.dispatch}
                    />
                </Box>                    
            </VStack>        
        </>
    )
}

export default CompleteQuiz;