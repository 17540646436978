import React, { useState, useEffect } from "react"
import {
    Card,
    CardBody,
    Box,
    Heading,
    VStack,
    Flex
} from '@chakra-ui/react'
import useApi from '../../hooks/useApi'
import LearningChart from "./LearningChart"
import {
    convertDateInIso8601Jst
 } from "../../CommonFunction"

const LearningStatusCard = (props) => {

    const { 
        getDailyQuizHistory
    } = useApi()

    const periodOfDays = 7

    const [quizHistory, setQuizHistory] = useState({
        dateList: [],
        quizCountList: [],
        accuracyList: [],
        totalQuizCount: 0,
        totalAccuracy: 0,
        consecuriveRecordMessage: ""
    })

    useEffect(() => {
        (async () => {

            try {
                const requestId = crypto.randomUUID()
                props.setRequestId(requestId)

                const items = await getDailyQuizHistory((periodOfDays - 1) * -1, requestId)

                if (items) {
                    const dailyQuizHistory = items?.daily

                    const dateList = []
                    const quizCountList = []
                    const accuracyList = []

                    let totalQuizCount = 0
                    let totalQuizCorrectCount = 0
                    let totalAccuracy = 0

                    if (dailyQuizHistory) {
                        for (const item of dailyQuizHistory) {
                            dateList.push(item.date.substring(0,10))
                            quizCountList.push(item.quizCount)
                            if (item.quizCount > 0) {
                                accuracyList.push(Math.round(item.quizCorrectCount / item.quizCount * 1000) / 10)
                                totalQuizCount += item.quizCount
                                totalQuizCorrectCount += item.quizCorrectCount
                            } else {
                                accuracyList.push(0)
                            }
                        }
                        totalAccuracy = totalQuizCount > 0 ? (Math.round(totalQuizCorrectCount / totalQuizCount * 1000) / 10) : 0
                    }

                    let consecuriveRecordMessage = ""
                    const consecutiveLearning = items?.consecutiveLearning

                    if (consecutiveLearning) {
                        if (consecutiveLearning?.endDate) {
                            const diffDays = Math.ceil((new Date(consecutiveLearning.endDate) - new Date(consecutiveLearning.startDate)) / (1000 * 3600 * 24)) + 1
                            for (let i = -1; i <= 0; ++i) {
                                const dt = new Date()
                                dt.setDate(dt.getDate() + i);
                                if (consecutiveLearning?.endDate === convertDateInIso8601Jst(dt)) {
                                    if (i === -1) {
                                        consecuriveRecordMessage = `${diffDays}日間継続学習中 (${consecutiveLearning.startDate.substring(0, 10)}～)`
                                    } else if (diffDays === 1) {
                                        consecuriveRecordMessage = `今日の学習完了`
                                    } else {
                                        consecuriveRecordMessage = `${diffDays}日間継続学習達成 (${consecutiveLearning.startDate.substring(0, 10)}～)`
                                    }
                                }
                            }
                        }
                    }

                    setQuizHistory({
                        dateList,
                        quizCountList,
                        accuracyList,
                        totalQuizCount,
                        totalAccuracy,
                        consecuriveRecordMessage
                    })            
                }
            } catch (error) {
                console.log("Exception in LearningStatusCard", error)
            } finally {
                props.setRequestId(null)            
            }


        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <>
            <Card
                direction={{ base: 'column', sm: 'row' }}
                overflow='hidden'
                variant='outline'
                w={[350, 450, 650]}
                boxShadow="none"
                mb="0px"
            >
                <CardBody
                    p="5px"
                >
                    <VStack>
                        <Heading 
                            size='md'
                            m="0px 0px 0px 24px"
                            width="100%"
                        >
                            最新の学習状況
                        </Heading>
                        {
                            quizHistory.consecuriveRecordMessage ?
                                <Box
                                    width="100%"
                                    p="0px 10px 0px 10px"
                                >
                                    <Box
                                        fontSize="md"
                                        p="2px 10px 2px 10px"
                                        bgColor="blue.50"
                                        width="100%"
                                    >
                                        {quizHistory.consecuriveRecordMessage}
                                    </Box>
                                </Box>
                                :
                                <></>
                        }
                        <Flex
                            height="300px"
                            flexWrap="nowrap"
                            direction="row"
                            justify="space-evenly"
                            width="100%"
                        >
                            <Box
                                height="100%"
                            >
                                <LearningChart
                                    quizHistory={quizHistory}
                                />
                            </Box>
                            <Box
                                height="100%"
                            >
                                <Flex
                                    direction="column"
                                    align="flex-start"
                                    justify="flex-start"
                                    mb="16px"
                                >
                                    <Heading
                                        size='md'
                                    >
                                        本日
                                    </Heading>
                                    <Box
                                        ml="8px"
                                        fontSize='sm'
                                    >
                                        <Box>
                                            回答数: {
                                                quizHistory.quizCountList[periodOfDays - 1] > 0 ?
                                                    quizHistory.quizCountList[periodOfDays - 1]
                                                    :
                                                    "ー"
                                            }
                                        </Box>
                                        <Box>
                                            正解率: {
                                                quizHistory.accuracyList[periodOfDays - 1] > 0 ?
                                                    `${quizHistory.accuracyList[periodOfDays - 1]}%`
                                                    :
                                                    "ー"
                                            }
                                        </Box>
                                    </Box>
                                </Flex>
                                <Flex
                                    direction="column"
                                    align="flex-start"
                                    justify="flex-start"
                                    mb="16px"
                                >
                                    <Heading
                                        size='md'
                                    >
                                        過去{periodOfDays}日間
                                    </Heading>
                                    <Box
                                        ml="8px"
                                        fontSize='sm'
                                    >
                                        <Box>
                                            回答数: {
                                                quizHistory.totalQuizCount > 0 ?
                                                    quizHistory.totalQuizCount
                                                    :
                                                    "ー"
                                            }
                                        </Box>
                                        <Box>
                                            正解率: {
                                                quizHistory.totalAccuracy > 0 ?
                                                    `${quizHistory.totalAccuracy}%`
                                                    :
                                                    "ー"
                                            }
                                        </Box>
                                    </Box>                                
                                </Flex>
                            </Box>
                        </Flex>
                    </VStack>
                </CardBody>
            </Card>          
        </>      
    )
}

export default LearningStatusCard