import React from "react"
import './App.css';
import Alert from "./components/Alert";
import { Routes, Route } from "react-router-dom"
import PageController from "./components/PageController";
import HtmlDocumentPage from "./components/HtmlDocumentPage";
import SignOutMainPage from "./components/SignOutMainPage";

const RoutesSettings = (props) => {
    return (
        <>
            <Routes>
                {
                    props.signInStatus === "signedIn" ?
                        <Route
                            path="/"
                            element={
                                <PageController
                                    setLaunchSubApp={props.setLaunchSubApp}
                                    launchSubApp={props.launchSubApp}
                                />
                            }
                        />
                        :
                        <Route
                            path="/"
                            element={
                                <SignOutMainPage
                                    errorMessages={props.errorMessages}
                                />
                            }
                        />                        
                }
                <Route
                    path="/terms-of-service"
                    element={
                        <HtmlDocumentPage
                            url="/terms_of_service.html"
                        />
                    }
                />
                <Route
                    path="/privacy-policy"
                    element={
                        <HtmlDocumentPage
                            url="/privacy_policy.html"
                        />
                    }
                />
                <Route
                    path="/data-deletion-instructions"
                    element={
                        <HtmlDocumentPage
                            url="/data_deletion_instructions.html"
                        />
                    }
                />                                            
            </Routes>
            <Alert />
        </>        
    )
}

export default RoutesSettings